import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/requests";

interface establishmentType {
    ID: number,
    NAME: string,
    EMAIL: string,
    PASSWORD: string,
    LINK: string,
    STATUS: string,
}

function ClientNewSaloonTable() {

    const { estabelecimento, salao, opcao } = useParams();
    const [data, setData] = useState({ NAME: "", TABLEOPTION: opcao });

    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();
        await axios.post(`${BASE_URL}/mesa`, data)
            .then(res => {
                navigate(`/estabelecimento/${estabelecimento}/salao/${salao}/opcao/${opcao}`)
            })
            .catch(err => {
            })
    }

    function handleChange(event: any) {
        setData({
            ...data,
            [event.target.name]: event.target.name != 'NAME' ? parseInt(event.target.value) : event.target.value
        })
    }

    return (
        <div className="p-4 sm:ml-64">
            <h2 className="mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white w-full text-center">Nova opção de mesa</h2>
            <section className="bg-gray-50 dark:bg-gray-900 flex flex-row items-center justify-center pt-8">
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Preencha com os dados da mesa
                        </h1>
                        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="NAME" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Identificação da mesa</label>
                                <input className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    type="text"
                                    name="NAME"
                                    onChange={handleChange}
                                    id="NAME" placeholder="Mesa 1"
                                    required={true} />
                            </div>
                            <button type="button" onClick={handleSubmit} className="w-full text-white bg-blue-700 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Salvar</button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ClientNewSaloonTable;