import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/requests";
import Swal from "sweetalert2";
import { Spinner } from "@material-tailwind/react";
import _ from 'lodash'


function EditTableOption() {

    const { estabelecimento } = useParams();
    const [data, setData] = useState<any>({});
    const [newData, setNewData] = useState<any>({});
    const [modalityData, setModalityData] = useState<any>({ ESTABLISHMENTID: estabelecimento });
    const [modalities, setModalities] = useState<any>([{}]);
    const [holidays, setHolidays] = useState<any>([]);
    const [holidayData, setHolidayData] = useState<any>({ ESTABLISHMENT: estabelecimento, DATE: getTodayDate() });
    const [title, setTitle] = useState<any>('');
    const [loding, setLoading] = useState(false);
    const weekDays = [
        { value: 6, label: "DOM", fullText: "Domingo" },
        { value: 0, label: "SEG", fullText: "Segunda" },
        { value: 1, label: "TER", fullText: "Terça" },
        { value: 2, label: "QUA", fullText: "Quarta" },
        { value: 3, label: "QUI", fullText: "Quinta" },
        { value: 4, label: "SEX", fullText: "Sexta" },
        { value: 5, label: "SAB", fullText: "Sabado" },
    ]

    const navigate = useNavigate();

    useEffect(() => {
        getData();
        getModalities();
        getHolidays();
    }, [])


    async function getData() {
        setLoading(true)
        await axios.get(`${BASE_URL}/local/${estabelecimento}`)
            .then(res => {
                if (res.status == 200) {
                    setData(res.data.data[0])
                    setTitle(data.DESCRIPTION)
                    setNewData({
                        ...newData, ['WEEKDAYS']: res.data.data[0].WEEKDAYS
                    })
                    setLoading(false)
                } else {
                    console.log(res)
                }
            })
            .catch(err => {
            })
    }

    async function getModalities() {
        setLoading(true)
        await axios.get(`${BASE_URL}/modalidade/local/${estabelecimento}`)
            .then(res => {
                setModalities(res.data.data)
                setLoading(false)
            })
            .catch(err => {
            })
    }

    async function getHolidays() {
        setLoading(true)
        await axios.get(`${BASE_URL}/feriado/local/${estabelecimento}`)
            .then(res => {
                setHolidays(res.data.data)
                setLoading(false)
            })
            .catch(err => {
            })
    }

    async function handleSubmit(e) {
        e.preventDefault();
        console.log(newData)
        await axios.patch(`${BASE_URL}/local/${estabelecimento}`, newData)
            .then(res => {
                Swal.fire({
                    text: `As configurações estabelecimento ${data.NAME} foram atualizadas!`,
                    icon: "success"
                });
            })
            .catch(err => {
                Swal.fire({
                    text: `As configurações estabelecimento ${data.NAME} não poderam ser atualizadas!`,
                    icon: "error",
                    footer: err.response.data.message
                });
            })
    }

    function handleChange(event: any) {
        if (event.target.name == "NAME") {
            setModalityData({ ...modalityData, [event.target.name]: event.target.value })
            console.log(modalityData)
        } else {
            setNewData({
                ...newData,
                [event.target.name]: event.target.name == 'NICKNAME' ? event.target.value.toLowerCase().trim() : event.target.value
            })
            console.log(newData)
        }
    }

    async function handleSubmitModality(e) {
        e.preventDefault();
        if (modalityData.NAME.length > 0) {
            await axios.post(`${BASE_URL}/modalidade/`, modalityData)
                .then(res => {
                    getModalities();
                    Swal.fire({
                        text: `Modalidade adicionada ${modalityData.NAME} adicionada!`,
                        icon: "success"
                    });
                })
                .catch(err => {
                    Swal.fire({
                        text: `A modalidade ${modalityData.NAME} não pode ser adicionada!`,
                        icon: "error",
                        footer: err.response.data.message
                    });
                })
        }
    }

    function handleChangeHolidayData(event: any) {
        setHolidayData({ ...holidayData, ['DATE']: event.target.value })
        console.log(event.target.value)
    }

    async function handleSubmitHoliday(e) {
        e.preventDefault();
        if (holidayData.DATE.length > 0) {
            let aux = _.filter(holidays, function (o) { return getFomattedDate(o.DATE) == getFomattedDate(holidayData.DATE) });
            // console.log(aux)
            if (aux.length > 0) {
                Swal.fire({
                    text: `Já existe feriado cadastrado para a data ${getFomattedDate(holidayData.DATE)}`,
                    icon: "error",
                    footer: "Selecione outra data"
                });
            } else {
                await axios.post(`${BASE_URL}/feriado/`, holidayData)
                    .then(res => {
                        getHolidays();
                        Swal.fire({
                            text: `Feriado adicionado no dia ${getFomattedDate(holidayData.DATE)}`,
                            icon: "success"
                        });
                    })
                    .catch(err => {
                        Swal.fire({
                            text: `O feriado em ${holidayData.DATE.replaceAll('-', '/')} não pode ser adicionado!`,
                            icon: "error",
                            footer: err.response.data.message
                        });
                    })
            }
        }
    }

    async function handleDeleteHoliday(holiday: any) {
        console.log(holiday)
        if (holiday.DATE.length > 0) {
            await axios.delete(`${BASE_URL}/feriado`, { data: holiday })
                .then(res => {
                    getHolidays();
                    Swal.fire({
                        text: `Feriado do dia ${getFomattedDate(holidayData.DATE)} removido!`,
                        icon: "success"
                    });
                })
                .catch(err => {
                    Swal.fire({
                        text: `O feriado do dia ${getFomattedDate(holidayData.DATE)} não pode ser removido!`,
                        icon: "error",
                        footer: err.response.data.message
                    });
                })
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });


    async function handleChangeImg(img) {
        if (img.length > 0) {
            let imgToBase64: any = await toBase64(img[0])
            let headerFilter = imgToBase64.toString().substring(0, imgToBase64.toString().search(';base64'));
            console.log(headerFilter)
            if (!headerFilter.startsWith('data:image/')) {
                Swal.fire({
                    title: "Arquivo Inválido",
                    icon: 'error',
                    text: "Selecione um arquivo: .png .jpg .jpeg ou .svg",
                    showDenyButton: false,
                    confirmButtonText: "OK",
                    confirmButtonColor: '#003775'
                })
            } else {
                setNewData({ ...newData, "LOGO": imgToBase64.toString() });
                console.log(newData)
            }
        } else {
            setNewData({ ...newData, "LOGO": "" });
        }
    }

    function getTodayDate() {
        let today = new Date();
        let finalDate = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}-${today.getDate() < 10 ? '0' + today.getDate() : today.getDate()}`
        // alert(finalDate)
        return finalDate;
    }

    function getFomattedDate(date: string) {
        return new Date(date).toLocaleString('pt-BR', { timeZone: 'UTC' }).substring(0, 10)
    }

    function handleChangeWeekDay(day: number) {
        let aux = newData.WEEKDAYS;
        if (aux == undefined) {
            setNewData({
                ...newData, ['WEEKDAYS']: `${day}`
            })
        } else {
            aux = newData.WEEKDAYS.trim().split('-')
            if (aux.includes(`${day}`)) {
                aux = _.filter(aux, function (o) { return o != `${day}` })
            } else {
                aux.push(day)
            }
            setNewData({
                ...newData, ['WEEKDAYS']: _.join(aux, '-')
            })
            console.log(newData.WEEKDAYS)
        }
    }


    return (
        <>
            {
                loding ?
                    <div className="p-4 sm:ml-64 h-screen flex items-center justify-center flex-col my-4">
                        <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    </div>
                    :
                    <div className="p-4 sm:ml-64">
                        <h2 className="mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white w-full text-center">Configurando <br /> {data.NAME}</h2>
                        <section className="bg-gray-50 dark:bg-gray-900 flex flex-col lg:flex-row lg:items-start md:flex-col md:items-center sm:flex-col justify-center py-8">
                            <div className="w-full bg-white rounded-lg shadow dark:border my-4 mx-4 md:mt-4 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                        Estabelecimento
                                    </h1>
                                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="NICK" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nick p/ Link</label>
                                            <input type="text" name="NICK" onChange={handleChange} id="NICK" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder={data.NICK} />
                                        </div>
                                        {/* <div>
                                            <label htmlFor="LOGO" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Logo (se houver)</label>
                                            <input id="LOGO" type="file" onChange={(e) => handleChangeImg(e.target.files)}
                                                className="mt-4 block w-full text-sm file:mr-4 file:rounded-md file:border-0 file:bg-blue-900 file:py-2 file:px-4 file:text-sm file:font-semibold file:text-white hover:file:bg-blue-700 focus:outline-none disabled:pointer-events-none disabled:opacity-60" />
                                        </div> */}
                                        <div>
                                            <label htmlFor="FIXEDTIME" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Horário fixo de reservas (se houver)</label>
                                            <input type="time" onChange={handleChange} name="FIXEDTIME" id="FIXEDTIME" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                defaultValue={data.FIXEDTIME} />
                                        </div>
                                        <div>
                                            <label htmlFor="EMAILADVICE" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Aviso no email (se houver)</label>
                                            <textarea onChange={handleChange} rows={3} cols={50} style={{ resize: 'none' }} name="EMAILADVICE" id="EMAILADVICE" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                defaultValue={data.EMAILADVICE} placeholder={data.EMAILADVICE} />
                                        </div>
                                        <div>
                                            <label htmlFor="WEEKDAYS" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Dias da semana</label>
                                            <div className="w-full flex flex-wrap items-start justify-start ">
                                                {
                                                    weekDays.map(day =>
                                                        <button type="button" className={`w-fit p-2 rounded-md shadow ${`${newData.WEEKDAYS}`.includes(`${day.value}`) ? 'bg-blue-900' : 'bg-gray-500'} text-white m-1`} onClick={() => handleChangeWeekDay(day.value)}>
                                                            {day.label}
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <button type="submit" className="w-full transition text-white bg-blue-900 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Salvar</button>
                                    </form>
                                </div>
                            </div>
                            <div className="w-full bg-white rounded-lg shadow dark:border my-4  mx-4 md:mt-4 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                        Modalidade de Reservas
                                    </h1>
                                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmitModality}>
                                        <div>
                                            <label htmlFor="NAME" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome</label>
                                            <input type="text" name="NAME" onChange={handleChange} id="NAME" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder='Ex.: La carte' required={true} minLength={5} />
                                        </div>
                                        <button type="submit" className="w-full transition text-white bg-blue-900 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Adicionar</button>
                                    </form>
                                    {
                                        loding ?
                                            <div className="w-full flex items-center justify-center flex-row my-4">
                                                <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                            </div> :
                                            modalities.length == 0 ?
                                                <>
                                                </> :
                                                <div className="">
                                                    <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                                                    {
                                                        modalities.map(modality => (
                                                            <div className="py-4 my-4 pl-4 bg-gray-100 items-center  text-gray-900 shadow rounded-lg w-full flex justify-between">
                                                                <span>{modality.NAME}</span>
                                                                <div className="w-4/12 flex justify-center px-2">
                                                                    {/* <a href={`estabeleciment/${estabelecimento}/configurar/modalidade/${modality.ID}`} className="bg-blue-900 hover:bg-blue-700 transition text-white rounded-lg px-2 py-2 mx-1">
                                                                        <svg className="w-6 h-6 text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                                                                        </svg>
                                                                    </a> */}
                                                                    <button className="bg-red-900 hover:bg-red-700 transition text-white rounded-lg px-2 py-1">
                                                                        <svg className="w-6 h-6 text-gray-300 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                                            <path fill-rule="evenodd" d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>))
                                                    }
                                                </div>
                                    }
                                </div>
                            </div>

                            <div className="w-full bg-white rounded-lg shadow dark:border my-4  mx-4 md:mt-4 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                        Feriados
                                    </h1>
                                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmitHoliday}>
                                        <div>
                                            <label htmlFor="HOLIDAY" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Estabelecimento fechado</label>
                                            <input type="date" name="HOLIDAY" id="HOLIDAY" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                onChange={handleChangeHolidayData}
                                                required={true}
                                                defaultValue={holidayData.DATE}
                                            />
                                        </div>
                                        <button type="submit" className="w-full transition text-white bg-blue-900 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Adicionar</button>
                                    </form>
                                    {
                                        loding ?
                                            <div className="w-full flex items-center justify-center flex-row my-4">
                                                <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                            </div> :
                                            holidays.length == 0 ?
                                                <>
                                                </> :
                                                <div className="">
                                                    <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                                                    {
                                                        holidays.map(holiday => (
                                                            <div className="py-4 my-4 pl-4 bg-gray-100 items-center  text-gray-900 shadow rounded-lg w-full flex justify-between">
                                                                <span>{getFomattedDate(holiday.DATE)}</span>
                                                                <div className="w-4/12 flex justify-center px-2">
                                                                    <button onClick={() => handleDeleteHoliday(holiday)} className="bg-red-900 hover:bg-red-700 transition text-white rounded-lg px-2 py-1">
                                                                        <svg className="w-6 h-6 text-gray-300 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                                            <path fill-rule="evenodd" d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
            }
        </>
    );
}

export default EditTableOption;