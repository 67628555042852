import { Stepper, Step, Button, step, Spinner } from "@material-tailwind/react";
import { HomeIcon, CogIcon, UserIcon, CalendarIcon, ClockIcon, CheckIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { IMaskInput } from "react-imask";
import { BASE_URL } from "../../../utils/requests";
import Swal from "sweetalert2";
import uuid from 'react-uuid';
import _ from 'lodash';

function NewReserve() {


    const { nick } = useParams();
    const [data, setData] = useState<any>({ ESTABLISHMENT: nick, TOKEN: uuid(), MODALITY: "Padrão", DATE: getTodayDate() });
    const [loading, setLoading] = useState<any>(false)
    const [holidays, setHolidays] = useState<any>([]);
    const [activeStep, setActiveStep] = useState(0);
    const [isLastStep, setIsLastStep] = useState(false);
    const [isFirstStep, setIsFirstStep] = useState(false);
    const [saloonsData, setSaloonsData] = useState<any>([{}])
    const [modalityData, setModalityData] = useState<any>([{}]);
    const [formCompleted, setFormCompleted] = useState(true);
    const [establishmentData, setEstablishmentData] = useState<any>({})
    const [tableAvaliableData, setTableAvaliableData] = useState<any>({})


    const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
    const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

    const navigate = useNavigate();

    useEffect(() => {
        getData();
        getHolidays();
        verifyTime();
    }, [])

    async function getData() {
        await axios.get(`${BASE_URL}/local/nick/${nick}`)
            .then(async res => {
                setEstablishmentData(res.data.data[0])
                await axios.get(`${BASE_URL}/local/nick/${nick}/saloes`)
                    .then(async res => {
                        console.log(res)
                        setSaloonsData(res.data.data)
                        getReservationOptions();
                        await axios.get(`${BASE_URL}/mesa/nick/${nick}/livre/${data.DATE}`)
                            .then(res => {
                                setTableAvaliableData(res.data.data)
                                setLoading(false)
                            })
                            .catch(err => {
                                Swal.fire({ icon: 'error', title: 'Não foi possivel encontrar mesas pra esta data' })
                            })
                    })

            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Ops,',
                    text: "Houve um problema com nossos serviços, por favor entre em contato com o estabelecimento para realizar sua reserva manualmente",
                    showConfirmButton: true,
                    didClose: () => {
                        setLoading(true)
                        navigate(`/404`);
                    }
                })

            })
    }

    function verifyTime() {
        let aux = new Date();
        var startTime = `${aux.getHours()}:${aux.getMinutes() < 10 ? `0${aux.getMinutes()}` : aux.getMinutes()}:00`;
        var endTime = `17:30:00`;
        var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
        if (parseInt(endTime.replace(regExp, "$1$2$3")) <= parseInt(startTime.replace(regExp, "$1$2$3"))) {
            // alert("End time is greater");
            Swal.fire({ icon: 'info', footer: 'O horário limite é as 17:30', text: 'O estabelecimento não está mais recebendo reservas para a data de hoje' })
            setData({
                ...data,
                ['DATE']: ""
            })
        }
    }


    async function getHolidays() {
        setLoading(true)
        await axios.get(`${BASE_URL}/feriado/nick/${nick}`)
            .then(res => {
                setHolidays(res.data.data)
                let aux = _.filter(res.data.data, function (o) { return getFomattedDate(o.DATE) == getFomattedDate(getTodayDate()) });

                if (aux.length > 0) {
                    Swal.fire({ icon: 'warning', text: 'O estabelecimento está fechado hoje, altere a data antes de continuar!' })
                    setData({
                        ...data,
                        ['DATE']: ""
                    })
                }
                setLoading(false)
            })
            .catch(err => {
            })
    }

    async function getReservationOptions() {
        setLoading(true)
        await axios.get(`${BASE_URL}/modalidade/nick/${nick}`)
            .then(res => {
                setModalityData(res.data.data)
                setEstablishmentData(res.data.establishment)
                setData({
                    ...data,
                    ['TIME']: res.data.establishment.FIXEDTIME != '19:00:00' ? res.data.establishment.FIXEDTIME : '19:00:00'
                })
                setLoading(false)
            })
            .catch(err => {
            })
    }

    function handleChangeDate(event: any) {
        if (event.target.value.length > 0) {
            let aux = _.filter(holidays, function (o) { return getFomattedDate(o.DATE) == getFomattedDate(new Date(event.target.value).toISOString()) });
            if (aux.length > 0) {
                setData({
                    ...data,
                    ['DATE']: ""
                })
                Swal.fire({ icon: 'warning', text: 'O estabelecimento não estará aberto na data ' + getFomattedDate(new Date(event.target.value).toISOString()) })
            } else {
                if (event.target.value == getTodayDate()) {
                    todayStillAcceptingReservation()
                } else {
                    if (establishmentData.WEEKDAYS.includes(`${new Date(event.target.value).getDay()}`)) {
                        setData({
                            ...data,
                            ['DATE']: event.target.value
                        })

                    } else {
                        Swal.fire({
                            icon: 'warning',
                            text: 'O Estabelecimento não abre neste dia da semana',
                            footer: 'Selecione outra data'
                        })
                        setData({
                            ...data,
                            ['DATE']: ""
                        })
                    }
                }
            }
        } else {
            setData({
                ...data,
                ['DATE']: ""
            })
        }

    }

    async function handleSubmit() {
        await axios.post(`${BASE_URL}/reserva/nick`, data)
            .then(res => {
                if (res.status == 200) {
                    Swal.fire({
                        title: 'Quase lá',
                        html: `
                       <div style="width:100%; display:flex; flex-direction:column; align-items:center;">
                       <img src="https://ehcvfmg.stripocdn.email/content/guids/CABINET_67e080d830d87c17802bd9b4fe1c0912/images/55191618237638326.png" alt='logo' style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                        width="35%">
                       <p class='mt-10 text-center'>
                        Enviaremos um email para confirmação no endereço informado, só a partir de confirmado que a reservar será validada. 
                        <br/>
                        </p>
                        <strong class='text-gray-400 text-center mt-8 text-sm'>
                        Caso não receba o email em até 5 minutos entre em contato com o estabelecimeto para confirmar manualmente
                        </strong>
                        </div>
                        `,
                        showConfirmButton: true,
                        didClose() {
                            navigate(`/reservado`)
                        },
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ops,',
                        text: "Houve um problema com nossos serviços, por favor entre em contato com o estabelecimento para confirmar sua reserva",
                        showConfirmButton: true
                    })
                }
            })
            .catch(err => {
            })
    }

    function handleChange(event: any) {
        if (event.target.name == 'PEOPLE_QTE' || event.target.name == 'SALOONID' || event.target.name == 'ESTABLISHMENT') {
            setData({
                ...data,
                [event.target.name]: parseInt(event.target.value)
            })
        } else
            setData({
                ...data,
                [event.target.name]: event.target.value
            })
    }

    function handleChangePeopleQte(event: any) {
        let aux = _.orderBy(tableAvaliableData, 'MAX_CAPACITY', 'desc')[0].MAX_CAPACITY;
        if (aux < event.target.value) {
            Swal.fire({ icon: 'warning', text: `A capacidade máxima disponível para reserva neste estabelecimento é de ${aux} pessoas`, title: 'Selcione outra mesa!' })
            setData({
                ...data,
                ['SALOONID']: '',
                ['MODALITY']: '',
                ['TABLEID']: '',
                ['PEOPLE_QTE']: ''
            })
        } else
            if (parseInt(event.target.value) < 1) {
                Swal.fire({ icon: 'warning', text: 'Informe uma quantidade válida!' })
                setData({
                    ...data,
                    ['PEOPLE_QTE']: ''
                })
            } else {
                setData({
                    ...data,
                    ['PEOPLE_QTE']: event.target.value
                })
            }

    }

    function handleChangeTable(table: any) {
        if (table.MAX_CAPACITY < data.PEOPLE_QTE) {
            Swal.fire({ icon: 'warning', text: `Esta mesa não tem capacidade para ${data.PEOPLE_QTE} pessoas`, title: 'Selecione outra mesa!' })
        } else
            if (table.SALOONID == data.SALOONID) {
                setData({
                    ...data,
                    ['SALOONID']: table.SALOONID,
                    ['TABLEID']: table.ID
                })
            } else {
                setData({
                    ...data,
                    ['SALOONID']: table.SALOONID,
                    ['MODALITY']: '',
                    ['TABLEID']: table.ID
                })
            }
    }

    function todayStillAcceptingReservation() {
        let aux = new Date();
        var startTime = `${aux.getHours()}:${aux.getMinutes() < 10 ? `0${aux.getMinutes()}` : aux.getMinutes()}:00`;
        var endTime = `17:30:00`;
        var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
        if (parseInt(endTime.replace(regExp, "$1$2$3")) <= parseInt(startTime.replace(regExp, "$1$2$3"))) {
            Swal.fire({ icon: 'info', footer: 'O horário limite é as' + endTime, text: 'O estabelecimento não está mais recebendo reservas para a data de hoje' })
            setData({
                ...data,
                ['DATE']: ""
            })
            return false;
        }
        return true;
    }

    async function verifyCompleted() {
        switch (activeStep) {
            case 0:
                if (!data.DATE || data.DATE.length == 0) {
                    Swal.fire({ icon: 'error', title: 'Selecione outra data' })
                    break;
                } else {
                    let aux = _.filter(holidays, function (o) { return getFomattedDate(o.DATE) == getFomattedDate(data.DATE) })
                    if (aux.length > 0) {
                        Swal.fire({ icon: 'warning', text: 'O estabelecimento está fechado hoje, altere a data antes de continuar!' })
                        // setData({
                        //     ...data,
                        //     ['DATE']: ""
                        // })
                        break;
                    }
                }
                if (data.DATE == getTodayDate() && !todayStillAcceptingReservation()) {
                    break;
                }
                if (!data.PEOPLE_QTE || data.PEOPLE_QTE.length == 0) {
                    Swal.fire({ icon: 'error', title: 'Informe o número de pessoas!' })
                    break;
                }
                else {
                    setData({
                        ...data,
                        ['TABLEID']: '',
                        ['SALOONID']: '',
                        ['MODALITY']: ''
                    })
                    setLoading(true)
                    await axios.get(`${BASE_URL}/mesa/nick/${nick}/livre/${data.DATE}`)
                        .then(res => {
                            setTableAvaliableData(res.data.data)

                            setLoading(false)
                            handleNext();
                        })
                        .catch(err => {
                            Swal.fire({ icon: 'error', title: 'Não foi possivel encontrar mesas pra esta data' })
                            setLoading(false)
                        })
                }
                break;
            case 1:
                if (data.TABLE == '' || data.MODALITY == '') {
                    Swal.fire({ icon: 'info', title: 'Selecione uma mesa e uma modalidade' })
                    break;
                }
                handleNext();
                break;
            case 2: let incomplete = Object.values(data).includes('') || Object.values(data).length < 12;
                setFormCompleted(incomplete);
                if (incomplete)
                    Swal.fire({ icon: 'info', title: 'Restam dados a serem informados' })
                else
                    handleNext();
                break;
            default: handleNext();
                break;

        }

    }

    function handleChangePhoneNumber(event: any) {
        setData({
            ...data,
            [event.target.name]: event.target.value
                .replaceAll("(", "")
                .replaceAll(")", "")
                .replaceAll("-", "")
                .replaceAll(" ", "")
        })
    }

    function handleChangeBirthDate(event: any) {
        let aux: string = event.target.value;
        if (event.target.value.length == 10) {

            if (!isNaN(new Date(aux).valueOf())) {
                setData({
                    ...data,
                    [event.target.name]: getDateOf(aux)
                })

            } else {
                if (!isNaN(new Date(getDateOf(aux)).valueOf())) {
                    setData({
                        ...data,
                        [event.target.name]: getDateOf(aux)
                    })

                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Informe uma data válida!',
                        timer: 3000,
                        timerProgressBar: true
                    })
                    setData({
                        ...data,
                        ['BIRTHDATE']: ''
                    })

                }
            }
        }


    }

    function getFomattedDate(date: string) {
        return new Date(date).toLocaleString('pt-BR', { timeZone: 'UTC' }).substring(0, 10)
    }

    function getTodayDate() {
        let today = new Date();
        let finalDate = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}-${today.getDate() < 10 ? '0' + today.getDate() : today.getDate()}`
        return finalDate;
    }

    function getDateOf(date: string) {

        let dataParts = date.split('/');
        let day = dataParts[0];
        let month = dataParts[1];
        let year = dataParts[2];

        return `${year}-${month}-${day}`
    }

    return (

        <div className="p-4 flex flex-col items-center bg-gray-50">{
            loading ?
                <div className="w-full flex items-center justify-center my-4">
                    <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </div>
                :
                <div className="lg:w-3/6 w-full md:w-full sm:w-full h-full">
                    <h2 className="mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white w-full text-center">{establishmentData.NAME} <br /> Nova Reserva </h2>
                    <div className="w-full h-[90%]">
                        <section className={`bg-gray-50 dark:bg-gray-900 flex flex-row items-center justify-center py-8`}>
                            <div className={` ${activeStep == 0 ? "flex" : "hidden"} bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md p-0dark:bg-gray-800 dark:border-gray-700`}>
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8 w-full">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                        Preencha com os dados da reserva
                                    </h1>
                                    <div>
                                        <label htmlFor="DATE" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data</label>
                                        <input type="date" name="DATE" defaultValue={data.DATE} min={getTodayDate()} onChange={handleChangeDate} id="DATE" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Família" required={true} />
                                    </div>
                                    <div>
                                        <label htmlFor="PEOPLE_QTE" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Número de pessoas <strong><small>(obrigatório)</small></strong></label>
                                        <input type="number" value={data.PEOPLE_QTE} onChange={handleChangePeopleQte} min={0} max={10} name="PEOPLE_QTE" id="PEOPLE_QTE" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required={true} />
                                    </div>
                                    <div>
                                        <label htmlFor="TIME" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Horário</label>
                                        <input type="time"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            id="TIME"
                                            name="TIME"
                                            defaultValue={data.TIME}
                                            readOnly={true}
                                            onChange={handleChange}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={` ${activeStep == 1 ? "flex" : "hidden"} bg-white rounded-lg shadow w-full dark:border md:mt-0  xl:p-0 dark:bg-gray-800 dark:border-gray-700`}>
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8 w-full">
                                    <h1 className="text-xl w-full text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                        Selecione a mesa e modalidade
                                    </h1>
                                    <label htmlFor="TABLE" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mesa</label>
                                    {
                                        loading ?

                                            <div className="w-full flex items-center justify-center my-4">
                                                <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                                Buscando mesas para esta data...
                                            </div>
                                            :
                                            <>
                                                {
                                                    tableAvaliableData ?
                                                        saloonsData.map(saloon => (
                                                            <div className="w-full flex flex-col items-center">
                                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{saloon.NAME}</h5>
                                                                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{saloon.DESCRIPTION}</p>
                                                                <div className="w-full p-1 flex flex-row  flex-wrap">
                                                                    {_.filter(tableAvaliableData, { 'SALOONID': saloon.ID }).map(table => (
                                                                        <button disabled={table.RESERVA} onClick={() => handleChangeTable(table)} className={` w-[46%] sm:w-[23%] sm:w-[23%] md:w-[18%] lg:w-[19%] flex flex-col items-center px-2 py-2 mr-2 mb-2 text-sm font-medium text-center rounded-lg transition ${table.RESERVA ? 'bg-red-900 text-white' : table.ID == data.TABLEID ? 'bg-green-900 text-white ' : 'bg-green-100 text-green-900 hover:bg-green-50'} shadow`}>
                                                                            <span className="my-[1px]">{table.OPTIONNAME}</span>
                                                                            <span className="my-[1px]">{table.RESERVA ? 'Reservada' : table.ID == data.TABLEID ? 'Selecionada' : 'Livre'}</span>
                                                                        </button>
                                                                    ))}
                                                                </div>
                                                            </div>

                                                        )) :
                                                        <>Não há mesas disponíveis</>
                                                }

                                                <label htmlFor="MODALITY" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Modalidade de consumo</label>
                                                <select name="MODALITY" defaultValue={data.RESERVEDTABLEID} onChange={handleChange} id="large" className="block w-full px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                    <option value={''}>Selecione uma modalidade</option>
                                                    {_.filter(modalityData, { SALOONID: data.SALOONID }).map(modality => (
                                                        <option key={modality.ID} value={modality.NAME}>{modality.NAME}</option>
                                                    ))}
                                                </select>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className={` ${activeStep == 2 ? "flex" : "hidden"} bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700`}>
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                        Preencha com seus dados pessoais
                                    </h1>
                                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                                        <div>
                                            <label htmlFor="NAME" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Seu nome *</label>
                                            <input
                                                type="text"
                                                name="NAME"
                                                onChange={handleChange} id="NAME"
                                                placeholder="João césar"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                required={true} />
                                        </div>
                                        <div>
                                            <label htmlFor="EMAIL" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Seu email *</label>
                                            <input type="EMAIL"
                                                name="EMAIL"
                                                onChange={handleChange} id="EMAIL"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="name@gmail.com"
                                                required={true} />
                                        </div>
                                        <div>
                                            <label htmlFor="PHONE" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Seu telefone *</label>
                                            <IMaskInput
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                mask="(00) 00000-0000"
                                                name="PHONE"
                                                onChange={handleChangePhoneNumber} id="PHONE"
                                                placeholder="(00)000000"
                                                required={true}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="BIRTHDATE" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data de aniversário *</label>
                                            <IMaskInput
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                mask="00/00/0000"
                                                name="BIRTHDATE"
                                                onChange={handleChangeBirthDate} id="BIRTHDATE"
                                                placeholder="15/03/1999"
                                                maxLength={10}
                                                minLength={10}
                                                required={true}
                                            />
                                            {/* <input type="date" name="BIRTHDATE" onChange={handleChange} id="DATE" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="02/03/1999" required={true} /> */}
                                        </div>
                                        <div>
                                            <label htmlFor="OBSERVATION" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Alguma observação?</label>
                                            <textarea
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                name="OBSERVATION"
                                                onChange={handleChange} id="OBSERVATION"
                                                placeholder="Aniversário de casamento"
                                                required={true}
                                                rows={3}
                                                cols={50}
                                            />
                                            {/* <input type="date" name="BIRTHDATE" onChange={handleChange} id="DATE" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="02/03/1999" required={true} /> */}
                                        </div>
                                        {/* {
                                        nonCompleted ?
                                            <p className="text-sm font-medium text-red-900 bg-red-100 rounded-lg text-center p-1 ring-red-900/20 ring-1 ring-inset">Existem informações não preenchidas anteriormente</p>
                                            :
                                            <>
                                            </>
                                    } */}
                                    </form>

                                </div>
                            </div>
                            <div className={` ${activeStep == 3 ? "flex" : "hidden"} bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700`}>
                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                        Confirme os dados da reserva
                                    </h1>
                                    {
                                        !formCompleted ?
                                            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                                                <div>
                                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome</label>
                                                    <h5 className="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                        {data.NAME}
                                                    </h5>
                                                </div>
                                                <div>
                                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contato</label>
                                                    <IMaskInput
                                                        className="bg-white text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white"
                                                        mask="(00) 00000-0000"
                                                        disabled={true}
                                                        value={data.PHONE}
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                                    <h5 className="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                        {data.EMAIL}
                                                    </h5>
                                                </div>
                                                <div>
                                                    <label htmlFor="DATETIME" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data e Horário</label>
                                                    <h5 className="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                        {getFomattedDate(data.DATE)} às {data.TIME}
                                                    </h5>
                                                </div>
                                                <div>
                                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Salão</label>
                                                    <h5 className="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                        {_.find(saloonsData, { ID: data.SALOONID }) ? _.find(saloonsData, { ID: data.SALOONID }).NAME : ''} <br /> {data.PEOPLE_QTE} pessoas
                                                    </h5>
                                                </div>
                                                <div>
                                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mesa e Modalidade</label>
                                                    <h5 className="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                        {_.find(tableAvaliableData, { ID: data.TABLEID }) ? _.find(tableAvaliableData, { ID: data.TABLEID }).NAME : ''} <br />
                                                        {data.MODALITY}
                                                    </h5>
                                                </div>
                                                <button type="button" onClick={handleSubmit}
                                                    className="w-full text-white bg-gray-900 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                                    Reservar!
                                                </button>
                                            </form>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                    <Stepper
                        className="mt-12"
                        activeStep={activeStep}
                        isLastStep={(value) => setIsLastStep(value)}
                        isFirstStep={(value) => setIsFirstStep(value)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                        <Step
                            // onClick={() => setActiveStep(0)}
                            placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <CalendarIcon className="h-5 w-5" />
                        </Step>
                        <Step
                            // onClick={() => setActiveStep(1)}
                            placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <ClockIcon className="h-5 w-5" />
                        </Step>
                        <Step
                            // onClick={() => setActiveStep(2)}
                            placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <UserIcon className="h-5 w-5" />
                        </Step>
                        <Step
                            // onClick={() => setActiveStep(3)}
                            placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <CheckIcon className="h-5 w-5" />
                        </Step>
                    </Stepper>
                    <div className="mt-16 flex justify-between">
                        <Button onClick={handlePrev} disabled={isFirstStep} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            Voltar
                        </Button>
                        <Button onClick={verifyCompleted} disabled={isLastStep} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            Próximo
                        </Button>
                    </div>
                </div>
        } </div>
    );
}

export default NewReserve;