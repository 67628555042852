import { createContext, useContext, useEffect, useState } from "react";
import useStorage from "../utils/useStorage";

export const StoreContext = createContext({});

const useStore = () => useContext(StoreContext) as any;

const StoreProvider = ({ children }: any) => {
    const [token, setToken] = useStorage("token");
    const [admin, setAdmin] = useStorage("admin");
    const [link, setLink] = useStorage("link");


    useEffect(() => {
    }, [token, admin]);

    return (
        <StoreContext.Provider
            value={{ token, setToken, admin, setAdmin, link, setLink }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export { StoreProvider, useStore };