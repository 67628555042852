import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/requests";
import Swal from "sweetalert2";
import { Spinner } from "@material-tailwind/react";

interface establishmentType {
    ID: number,
    NAME: string,
    EMAIL: string,
    PASSWORD: string,
    LINK: string,
    STATUS: string,
}

function EditTableOption() {

    const { estabelecimento, salao, opcao } = useParams();
    const [data, setData] = useState<any>({});
    const [title, setTitle] = useState<any>('');
    const [loagind, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, [])


    async function getData() {
        setLoading(true)
        await axios.get(`${BASE_URL}/opcao-mesa/${opcao}`)
            .then(res => {
                if (res.status == 200) {
                    setData(res.data.data[0])
                    setTitle(data.DESCRIPTION)
                    setLoading(false)
                } else {
                    console.log(res)
                }
            })
            .catch(err => {
            })
    }

    async function handleSubmit(e) {
        e.preventDefault();
        await axios.patch(`${BASE_URL}/opcao-mesa/${opcao}`, data)
            .then(res => {
                Swal.fire({
                    text: `As mesas do tipo ${data.DESCRIPTION} foram atualizadas!`,
                    icon: "success",
                    didClose: () => {

                        navigate(`/estabelecimento/${estabelecimento}/opcap/${opcao}`)
                    }
                });
            })
            .catch(err => {
                Swal.fire({
                    text: `As mesas do tipo ${title} não poderam ser atualizadas!`,
                    icon: "error",
                    footer: err.response.data.message
                });
            })
    }

    function handleChange(event: any) {
        setData({
            ...data,
            [event.target.name]: event.target.name != 'DESCRIPTION' ? parseInt(event.target.value) : event.target.value
        })
    }

    return (

        loagind ?
            <div className="w-full flex items-center justify-center flex-row my-4">
                <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </div>
            :
            <div className="p-4 sm:ml-64">
                <h2 className="mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white w-full text-center">Editando opção de mesa: {title}</h2>
                <section className="bg-gray-50 dark:bg-gray-900 flex flex-row items-center justify-center pt-8">
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Preencha com os dados das mesas
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="DESCRIPTION" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Descrição</label>
                                    <input type="text" name="DESCRIPTION" onChange={handleChange} id="DESCRIPTION" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder={data.DESCRIPTION} required={true} />
                                </div>
                                <div>
                                    <label htmlFor="MAX_CAPACITY" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Capacidade (pessoas)</label>
                                    <input type="number" onChange={handleChange} name="MAX_CAPACITY" id="MAX_CAPACITY" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder={data.MAX_CAPACITY} required={true} />
                                </div>
                                <button type="submit" className="w-full transition text-white bg-blue-900 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Salvar</button>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
    );
}

export default EditTableOption;