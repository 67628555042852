import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../utils/requests";
import { useStore } from "../../../Storage/Provider";
import { title } from "process";

function Login() {
  const [data, setData] = useState({ email: "", password: "" });
  const { token, setToken, admin, setAdmin, setLink } = useStore();
  const [disable, setDisable] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  async function handleSubmit(e) {
    e.preventDefault();
    if (data.email.length == 0 || data.password.length == 0) {
      Swal.fire({
        title: "Restam dados a serem informados!",
      });
    } else {
        console.log(data)
      await axios
        .post(`${BASE_URL}/local/login`, data)
        .then((res) => {
          if (res.status != 200) {
            Swal.fire({
              title: "Oops!",
              text: res.data.message,
              icon: "warning",
            });
          } else if (res.data.data[0].STATUS == 0) {
            Swal.fire({
              title: "Oops!",
              text: "Este estabelecimento está inativo, contate o suporte",
              icon: "warning",
            });
          } else {
            setToken(res.data.data[0].ID);
            setLink(
              `http://reservei.controleautomacao.com.br/${res.data.data[0].NICK}/nova-reserva`
            );
            setAdmin(false);
            navigate("/estabelecimento/" + res.data.data[0].ID + "/dashboard");
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: "Ops!",
            text: err.response.data.message,
            icon: "error",
          });
        });
    }
  }

  function handleChange(event: any) {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <section className="bg-gray-900 h-screen">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="flex flex-col text-white items-center rounded-lg mt-4 mb-10">
          <span className="ms-3 font-bold">Reservei</span>
          <img
            src="https://controlesistemas-image-collection.s3.sa-east-1.amazonaws.com/logo_branca.png"
            className="w-[30%] text-bold"
            alt=""
          />
        </div>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Faça login com a sua conta
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  id="email"
                  placeholder="name@company.com"
                  required={true}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Senha
                </label>
                <input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required={true}
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                      required={false}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="remember"
                      className="text-gray-500 dark:text-gray-300"
                    >
                      Lembre-se
                    </label>
                  </div>
                </div>
                <a
                  href="#"
                  className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Esqueceu a senha?
                </a>
              </div>
              <button
                type="submit"
                className={`w-full text-white hover:bg-primary-700 bg-gray-900  hover:bg-blue-900 transition font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
              >
                Entrar
              </button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Não possui uma conta?{" "}
                <a
                  target="_blank"
                  href="https://controleautomacao.com.br/#cadastro"
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Contate-nos
                </a>
              </p>
            </form>
          </div>
        </div>
        <a
          href={`/restricted/login`}
          className="flex items-center p-2  mt-8 text-gray-100 rounded-lg dark:text-white hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-700 group"
        >
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z"
              clip-rule="evenodd"
            />
          </svg>

          <span className="ms-3">Área restrita</span>
        </a>
      </div>
    </section>
  );
}

export default Login;
