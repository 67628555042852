import React, { useContext } from "react";
import { Navigate } from "react-router";
import { useStore } from "../../Storage/Provider";
import { useParams } from "react-router-dom";

const ProtectedRoute = ({children}:any) => { //Route com proteção de acesso

  const { token, admin } = useStore()
  const {estabelecimento} = useParams();
  const path = window.location.pathname; //Pega o caminho atual da página
  
  if (!token) { //Se não existir token, redireciona para a página de login
    return <Navigate to="/login" replace />;
  } else if (token && !admin && path.includes('admin') || estabelecimento && token != estabelecimento) { //Se existir token e o usuário logado não for admin, mas a página atual for admin, redireciona para a página de usuário
    alert("Não autorizado")
    return <Navigate to="/login" replace />;
  }

  return children;
};
export default ProtectedRoute;