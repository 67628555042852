import { Stepper, Step, Button, step, Spinner } from "@material-tailwind/react";
import { HomeIcon, CogIcon, UserIcon, CalendarIcon, ClockIcon, CheckIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IMaskInput } from "react-imask";

interface establishmentType {
    ID: number,
    NAME: string,
    EMAIL: string,
    PASSWORD: string,
    LINK: string,
    STATUS: string,
}

function NotFound() {

    return (
        <div className="w-full h-screen flex flex-col items-center bg-gray-900">
            <div className="flex flex-col text-white items-center rounded-lg mt-4 mb-10">
                <span className="ms-3 font-bold">Reservei</span>
                <img src="https://controlesistemas-image-collection.s3.sa-east-1.amazonaws.com/logo_branca.png" className="w-5/12" />
            </div>
            <h2 className="mb-4 text-2xl font-bold tracking-tight text-white dark:text-white w-full text-center">Ops!, não encontramos esta página!</h2>
        </div>
    );
}

export default NotFound;