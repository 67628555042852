import { Tabs, TabsHeader, Tab, TabsBody, TabPanel, Button } from "@material-tailwind/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/requests";
// import CalendarMonthView from "react-calendar-month-view";


interface establishmentType {
    ID: number,
    NAME: string,
    EMAIL: string,
    PASSWORD: string,
    LINK: string,
    STATUS: string,
}

function ClientDashboard() {

    const { estabelecimento } = useParams();
    const [establishmentData, setEstablishmentData] = useState<establishmentType>({
        ID: 0,
        NAME: "",
        EMAIL: "",
        PASSWORD: "",
        LINK: "",
        STATUS: "",
    });
    const reservesFilterStatus = [{
        STATUS: 1,
        LABEL: "Confirmadas",
        COLOR: 'bg-green-600 text-white'
    }, {
        STATUS: 0,
        LABEL: "Pendente",
        COLOR: 'bg-yellow-600 text-gray-900'
    },]
    const [activeReservesFilter, setActiveReservesFilter] = useState(reservesFilterStatus[1])
    const handleReservesFilterClick = (filter: any) => {
        setActiveReservesFilter(filter)
    }
    const tableStatus = [{
        STATUS: 2,
        LABEL: "Livre",
        COLOR: "bg-green-600"
    }, {
        STATUS: 0,
        LABEL: "Reservada",
        COLOR: "bg-gray-900"
    }, {
        STATUS: 1,
        LABEL: "Ocupada",
        COLOR: "bg-red-600"
    },]
    const [activeTableFilter, setActiveTableFilter] = useState(tableStatus[1])
    const handleTableFilterClick = (filter: any) => {
        setActiveTableFilter(filter)
    }
    const [todayTableData, setTodayTableData] = useState<any>([{}])
    const [reservesResumeCount, setReservesResumeCount] = useState<any>({})
    const [reservesResume, setReservesResume] = useState<any>([{}])

    useEffect(() => {
        getData();
        getTablesData();
        getReserveResumeCount();
        getReserveResume();
    }, [])


    async function getData() {
        await axios.get(`${BASE_URL}/local/${estabelecimento}`)
            .then(res => {
                if (res.status != 200) {
                    // navigate('/login');
                } else {
                    setEstablishmentData(res.data.data[0])
                }
            })
            .catch(err => {
            })
    }

    async function getTablesData() {
        await axios.get(`${BASE_URL}/mesa/local/${estabelecimento}/tempo-real`)
            .then(res => {
                if (res.status != 200) {
                    // navigate('/login');
                } else {
                    setTodayTableData(res.data.data)
                }
            })
            .catch(err => {
            })
    }

    async function getReserveResumeCount() {
        await axios.get(`${BASE_URL}/local/${estabelecimento}/resumo-reservas`)
            .then(res => {
                setReservesResumeCount(res.data.data[0])
            })
            .catch(err => {
            })
    }

    async function getReserveResume() {
        await axios.get(`${BASE_URL}/local/${estabelecimento}/status-reservas`)
            .then(res => {
                if (res.status != 200) {
                } else {
                    setReservesResume(res.data.data)
                }
            })
            .catch(err => {
            })
    }

    function getFomattedDate(date: string) {
        return new Date(date).toLocaleString('pt-BR', { timeZone: 'UTC' }).substring(0, 10)
    }

    function renderDay(day) {
        return (
            <div className="w-20 bg-green-200 z-100">
                <small>Teste</small>
            </div>
        );
    }


    return (
        <>
            <div className="p-4 sm:ml-64 flex items-center flex-col">
                <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">{establishmentData.NAME}</h1>
                <div>
                    <dl className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        <div className="relative overflow-hidden rounded-lg bg-gray-50 px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                            <dt>
                                <div className="absolute rounded-md bg-red-600 p-3">
                                    <svg className="h-6 w-6 text-gray-900" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                    </svg>
                                </div>
                                <p className="ml-16 truncate text-sm font-medium text-gray-900">Reservas do dia</p>
                            </dt>
                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-900">{reservesResumeCount.TOTAL_TODAY || 0}</p>

                                <div className="absolute inset-x-0 bottom-0 bg-red-600 px-4 py-4 sm:px-6">
                                    <div className="text-sm">
                                    </div>
                                </div>
                            </dd>
                        </div>
                        <div className="relative overflow-hidden rounded-lg bg-gray-50 px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                            <dt>
                                <div className="absolute rounded-md bg-yellow-600 p-3">
                                    <svg className="h-6 w-6 text-gray-900" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                    </svg>
                                </div>
                                <p className="ml-16 truncate text-sm font-medium text-gray-900">Total do mês</p>
                            </dt>
                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-900">{reservesResumeCount.TOTAL_MONTH}</p>

                                <div className="absolute inset-x-0 bottom-0 bg-yellow-600 px-4 py-4 sm:px-6">
                                    <div className="text-sm">
                                    </div>
                                </div>
                            </dd>
                        </div>
                        <div className="relative overflow-hidden rounded-lg bg-gray-50 px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                            <dt>
                                <div className="absolute rounded-md bg-green-600 p-3">
                                    <svg className="h-6 w-6 text-gray-900" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                    </svg>
                                </div>
                                <p className="ml-16 truncate text-sm font-medium text-gray-900">Acumulado</p>
                            </dt>
                            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-900">{reservesResumeCount.TOTAL_ACCUMULATED}</p>

                                <div className="absolute inset-x-0 bottom-0 bg-green-600 px-4 py-4 sm:px-6">
                                    <div className="text-sm">
                                    </div>
                                </div>
                            </dd>
                        </div>
                    </dl>
                </div>
                <div className="flex w-full justify-around py-8 flex-col-reverse lg:flex-row sm:flex-col-reverse md:flex-col-reverse ">
                    <div className="lg:w-[47%] sm:w-full py-4 my-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 shadow bg-white">
                        <h1 className="text-xl  text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Mesas</h1>
                        <section className="w-full flex flex-col gap-12 lg:px-4 md:px-4 px-5">
                            <div className="flex w-full md:justify-center items-center mt-4 md:gap-6 gap-3 flex-wrap">
                                {
                                    tableStatus.map((filter) => (
                                        <Button onClick={() => handleTableFilterClick(filter)} type="button" className={`focus:outline-none border-2 hover:bg-gray-400 hover:text-white font-medium rounded-lg text-sm px-5 text-white py-2.5 mb-2 capitalize ${activeTableFilter.STATUS === filter.STATUS ? activeTableFilter.COLOR : "bg-slate-300 text-gray-900"}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                            {filter.LABEL}
                                        </Button>
                                    ))
                                }
                                {/* filtered cards display */}
                                <main className="w-full  grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-x-5 gap-y-8 md:mt-8">
                                    {
                                        todayTableData.length > 0 ?
                                            todayTableData.map((item, index) => (
                                                <div key={index} className={`w-full text-center cursor-pointer transition-all duration-200 rounded-lg shadow ${activeTableFilter.COLOR}  ${activeTableFilter.STATUS === item.STATUS ? 'block' : "hidden"}`}>
                                                    <div className="p-5">
                                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-white">
                                                            {item.NAME}
                                                        </h5>
                                                        <p className="mb-3 font-normal text-white">
                                                            {item.SALOON}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <>
                                                Não há mesas cadastradas
                                            </>
                                    }
                                </main>
                            </div>
                        </section>
                    </div>
                    <div className="lg:w-[47%] sm:w-full py-4 my-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 shadow bg-white">
                        <h1 className="text-xl  text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Reservas</h1>
                        <section className="w-full flex flex-col gap-12 lg:px-4 md:px-4 px-5">
                            {
                                reservesResume.length > 0 ?
                                    <div className="flex w-full md:justify-center items-center mt-4 md:gap-6 gap-3 flex-wrap">
                                        {
                                            reservesFilterStatus.map((filter) => (
                                                <Button onClick={() => handleReservesFilterClick(filter)} type="button" className={`focus:outline-none border-2 hover:bg-gray-400 hover:text-white font-medium rounded-lg text-sm px-5 text-white py-2.5 mb-2 capitalize ${activeReservesFilter.STATUS === filter.STATUS ? filter.COLOR : "bg-slate-300 text-gray-900"}`} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                                    {filter.LABEL}
                                                </Button>
                                            ))
                                        }
                                        {/* filtered cards display */}
                                        <main className="w-full">
                                            {
                                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                        <tr>
                                                            <th scope="col" className="px-4 py-3">Nome</th>
                                                            <th scope="col" className="px-4 py-3 text-center">Pessoas</th>
                                                            <th scope="col" className="px-4 py-3 text-center">Salão</th>
                                                            <th scope="col" className="px-4 py-3 text-center">Consumo</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {reservesResume.map(reserve => (
                                                            <tr key={reserve.ID} className={`transition border-b dark:border-gray-700 cursor-pointer hover:bg-white hover:text-gray-900 ${activeReservesFilter.COLOR} ${activeReservesFilter.STATUS === reserve.CONFIRMED ? 'tr' : "hidden"}`}>
                                                                <th scope="row" className="px-4 py-3 font-medium whitespace-nowrap dark:text-white">{reserve.NAME}</th>
                                                                <td className="px-4 py-3 text-center">{reserve.PEOPLE_QTE}</td>
                                                                <td className="px-4 py-3 text-center">{reserve.SALOON}</td>
                                                                <td className="px-4 py-3 text-center">{reserve.MODALITY}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            }
                                        </main>
                                    </div>
                                    :
                                    <>
                                        <p className="w-full font-bold text-gray-400 text-center mt-4">Sem reservas até o momento</p>
                                    </>
                            }
                        </section>
                    </div>

                </div>
                <div className="flex w-full items-center justify-center">
                    {/* <CalendarMonthView renderDay={renderDay} />; */}
                </div>
            </div>
        </>
    );
}

export default ClientDashboard;