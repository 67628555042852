import Sidebar from './components/Sidebar';
import Login from './pages/client/Login/Login';
import { StoreProvider } from './Storage/Provider';
import ProtectedRoute from './routes/Private/Private';
import NewReserve from './pages/user/Reserve/NewReserve';
import ClientSaloon from './pages/client/Saloon/ClientSaloon';
import ClientSaloons from './pages/client/Saloon/ClientSaloons';
import ClientNewSaloon from './pages/client/Saloon/ClientNewSaloon';
import ReserveHistory from './pages/client/Reserve/TodayReserves';
import ClientNewReserve from './pages/client/Reserve/ClientNewReserve';
import ClientDashboard from './pages/client/Dashboard/ClientDashboard';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import ClientNewSaloonTable from './pages/client/SaloonTable/ClientNewSaloonTable';
import ClientNewTableOption from './pages/client/TableOption/ClientNewTableOption';
import ClientSaloonTableOption from './pages/client/TableOption/ClientSaloonTableOption';
import ReserveConfirmation from './pages/user/Reserve/ReserveConfirmation';
import TodayReserves from './pages/client/Reserve/TodayReserves';
import AdminLogin from './pages/admin/Login/AdminLogin';
import AdminDashboard from './pages/admin/Dashboard/AdminDashboard';
import AdminSideBar from './components/admin/AdminSideBar';
import Establishments from './pages/admin/Establishment/Establishments';
import NewEstablishment from './pages/admin/Establishment/NewEstablishment';
import EditTableOption from './pages/client/TableOption/EditTableOption';
import EditSaloon from './pages/client/Saloon/EditSaloon';
import Configuration from './pages/client/Config/Configuration';
import EditModality from './pages/client/Config/EditModality';
import Reserved from './pages/user/Reserve/Reserved';
import NotFound from './pages/error/404';
import EditSaloonTable from './pages/client/SaloonTable/EditSaloonTable';
import ListReservations from './pages/client/Reserve/ListReservations';


function App() {

  const EstablishMentLayout = () => (
    <>
      <Sidebar />
      <Outlet />
    </>
  );

  const AdminLayout = () => (
    <>
      <AdminSideBar />
      <Outlet />
    </>
  );


  return (
    <StoreProvider>
      <BrowserRouter>
        <Routes>

          <Route path="/*" element={<NotFound />} />
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/restricted/login" element={<AdminLogin />} />
          <Route path="/:nick/nova-reserva" element={<NewReserve />} />
          <Route path="/reservado" element={<Reserved />} />
          <Route path="/reserve-confirm/:reservetoken" element={<ReserveConfirmation />} />

          {/* Rotas Clientes */}

          <Route element={<ProtectedRoute><EstablishMentLayout /></ProtectedRoute>} >
            <Route path="/estabelecimento/:estabelecimento" element={<ClientDashboard />} />
            <Route path="/estabelecimento/:estabelecimento/dashboard" element={<ClientDashboard />} />
            <Route path="/estabelecimento/:estabelecimento/configurar" element={<Configuration />} />
            <Route path="/estabelecimento/:estabelecimento/configurar/modalidade/:modalidade" element={<EditModality />} />
            <Route path="/estabelecimento/:estabelecimento/saloes" element={<ClientSaloons />} />
            <Route path="/estabelecimento/:estabelecimento/novo-salao" element={<ClientNewSaloon />} />
            <Route path="/estabelecimento/:estabelecimento/salao/:salao" element={<ClientSaloon />} />
            <Route path="/estabelecimento/:estabelecimento/novo-salao" element={<ClientNewSaloon />} />
            <Route path="/estabelecimento/:estabelecimento/editar-salao/:salao" element={<EditSaloon />} />
            <Route path="/estabelecimento/:estabelecimento/salao/:salao/opcao/:opcao/" element={<ClientSaloonTableOption />} />
            <Route path="/estabelecimento/:estabelecimento/salao/:salao/nova-mesa" element={<ClientNewTableOption />} />
            <Route path="/estabelecimento/:estabelecimento/editar-opcao/:opcao/" element={<EditTableOption />} />
            <Route path="/estabelecimento/:estabelecimento/salao/:salao/opcao/:opcao/nova-mesa" element={<ClientNewSaloonTable />} />
            <Route path="/estabelecimento/:estabelecimento/salao/:salao/opcao/:opcao/mesa/editar-mesa/:mesa" element={<EditSaloonTable />} />
            <Route path="/estabelecimento/:estabelecimento/reserva" element={<ClientNewReserve />} />
            <Route path="/estabelecimento/:estabelecimento/reservas" element={<ListReservations />} />
            <Route path="/estabelecimento/:estabelecimento/hoje" element={<TodayReserves />} />
          </Route>

          {/* Rotas admin */}

          <Route element={<ProtectedRoute><AdminLayout /></ProtectedRoute>} >
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/estabelecimentos" element={<Establishments />} />
            <Route path="/admin/novo-estabelecimento" element={<NewEstablishment />} />
          </Route>
        </Routes>
      </BrowserRouter >
    </StoreProvider>
  );
}

export default App;
