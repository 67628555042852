import { Card, CardBody, Collapse, Spinner, Typography } from "@material-tailwind/react";
import axios from "axios";
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/requests"
import Swal from "sweetalert2";
import _ from 'lodash';


function ClientSaloon() {
    const [saloonData, setSaloonData] = useState<any>({});

    const { estabelecimento, salao, opcao } = useParams();
    const [establishmentSaloon, setEstablishmentSaloon] = useState<any>({});
    const [modalities, setModalities] = useState<any>([{}]);

    const [loagind, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getData();
        getSaloon();
        getModalities();
    }, [])


    async function getData() {
        await axios.get(`${BASE_URL}/salao/${salao}/mesas`)
            .then(async res => {
                if (res.status != 200) {
                } else {
                    setSaloonData(res.data.data)
                    console.log(res.data)
                }
            })
            .catch(err => {
            })

    }

    async function getSaloon() {
        setLoading(true);
        await axios.get(`${BASE_URL}/salao/${salao}`)
            .then(res => {
                setEstablishmentSaloon(res.data.data[0])
                console.log(establishmentSaloon)
                setLoading(false)
            })
            .catch(err => {
            })
    }

    async function getModalities() {
        setLoading(true)
        await axios.get(`${BASE_URL}/modalidade/resumo-salao/${salao}/${estabelecimento}`)
            .then(res => {
                setModalities(res.data.data)
                setLoading(false)
                console.log(res.data.data)
            })
            .catch(err => {
            })
    }

    function handleDeleteTable(id: number) {
        let table = _.find(saloonData, { ID: id });
        Swal.fire({
            icon: 'question',
            title: "Tem certeza?",
            text: `Esta prestes a excluir as mesas do tipo ${table.DESCRIPTION}`,
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: `Sim, excluir`,
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isDenied) {
                await axios.delete(`${BASE_URL}/opcao-mesa/${table.ID}`)
                    .then(res => {
                        console.log(res)
                        Swal.fire({
                            text: `Mesas do tipo ${table.DESCRIPTION} excluida!`,
                            icon: "success",
                            // footer: res.data.message
                        });
                        getData();
                    })
                    .catch(err => {
                        Swal.fire({
                            text: `As mesas do tipo ${table.NAME} não poderam ser excluidas!`,
                            icon: "error",
                            footer: err.response.data.message
                        });
                    })
            }
        })
    }

    function handleDeleteSaloon() {
        Swal.fire({
            icon: 'question',
            title: "Tem certeza?",
            text: `Esta prestes a excluir ${establishmentSaloon.NAME}`,
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: `Sim, excluir`,
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isDenied) {
                await axios.delete(`${BASE_URL}/salao/${establishmentSaloon.ID}`)
                    .then(res => {
                        console.log(res)
                        Swal.fire({
                            text: `${establishmentSaloon.NAME} excluído!`,
                            icon: "success",
                            footer: res.data.message
                        });
                        navigate(`/estabelecimento/${estabelecimento}/saloes`)
                    })
                    .catch(err => {
                        Swal.fire({
                            text: `${establishmentSaloon.NAME} não pode ser excluído!`,
                            icon: "error",
                            footer: err.response.data.message
                        });
                    })
            }
        })
    }

    async function handleAddReserveOption(id: number) {
        await axios.post(`${BASE_URL}/modalidade/opcao-salao`, { SALOONID: salao, MODALITY: id })
            .then(res => {
                console.log(res)
                Swal.fire({
                    text: res.data.message,
                    icon: "success"
                });
                getModalities();
            })
            .catch(err => {
                Swal.fire({
                    text: `Opção não adicionada`,
                    icon: "error",
                    footer: err.response.data.message
                });
            })
    }


    return (
        loagind ?
            <div className="w-full flex items-center justify-center my-4">
                <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </div>
            :
            <div className="p-4 sm:ml-64">
                <div className="w-full flex flex-row items-center">
                    <div className="w-[90%] ">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{establishmentSaloon.NAME} </h5>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{establishmentSaloon.DESCRIPTION}</p>
                    </div>
                    <div className="w-[5%] flex flex-row justify-center">
                        <button type="button" onClick={() => { navigate(`/estabelecimento/${estabelecimento}/editar-salao/${establishmentSaloon.ID}`) }} className="text-white  bg-blue-800  mr-1 hover:bg-blue-600  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 flex items-center justify-center dark:hover:bg-blue-100 dark:focus:ring-blue-800">
                            <svg className="w-6 h-6 text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                            </svg>
                        </button>
                        <button type="button" onClick={handleDeleteSaloon} className="text-white bg-red-600 hover:bg-red-900  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 flex items-center justify-center dark:hover:bg-blue-100 dark:focus:ring-blue-800">
                            <svg className="w-6 h-6 text-gray-300 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>
                <hr className="h-px mt-1 mb-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                <div className="w-full flex flex-row items-center">
                    <div className="w-[90%] ">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Opções de Mesa</h5>
                    </div>
                </div>
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 flex flex-row flex-wrap">
                    {
                        saloonData.length > 0 ?
                            saloonData.map((tableOption: any) => (
                                <div key={tableOption.ID} className="cursor-pointer flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow py-4  text-center w-full md:flex-row lg:w-3/12 lg:flex-col md:w-full md:max-w-xl mb-2 lg:mb-2 lg:mr-2 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                                    <div onClick={() => { navigate(`/estabelecimento/${estabelecimento}/salao/${salao}/opcao/${tableOption.ID}`) }} className="flex flex-col justify-between p-4 leading-normal w-full md:w-full">
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{tableOption.DESCRIPTION}</h5>
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Capacidade: {tableOption.MAX_CAPACITY} pessoas</p>
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Total: {tableOption.TOTAL} mesas</p>
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Total: {parseInt(tableOption.TOTAL) * parseInt(tableOption.MAX_CAPACITY)} lugares</p>
                                    </div>
                                    <div className="w-[80%] flex flex-row justify-center">
                                        {/* <button type="button" onClick={()=>{navigate(`/estabelecimento/${estabelecimento}/editar-opcao/${tableOption.ID}`)}} className="text-white  bg-blue-800  mr-1 hover:bg-blue-600  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 flex items-center justify-center dark:hover:bg-blue-100 dark:focus:ring-blue-800">
                                            <svg className="w-6 h-6 text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                                            </svg>
                                        </button> */}
                                        <button type="button" onClick={() => { handleDeleteTable(tableOption.ID) }} className="text-white bg-red-600 hover:bg-red-900  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 flex items-center justify-center dark:hover:bg-blue-100 dark:focus:ring-blue-800">
                                            <svg className="w-6 h-6 text-gray-300 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                <path fill-rule="evenodd" d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ))
                            :
                            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Não há opções de mesas nesse salão</p>
                    }
                </div>
                <a href={`/estabelecimento/${estabelecimento}/salao/${establishmentSaloon.ID}/nova-mesa`} className="flex flex-row items-center mt-3 bg-white border border-gray-200 rounded-lg shadow py-4  text-center w-full md:flex-row lg:w-3/12 lg:flex-col md:w-full md:max-w-xl mb-2 lg:mb-2 lg:mr-2 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                    <div className="flex flex-col items-center justify-center leading-normal w-full h-full md:w-full">
                        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Adicionar opção de mesa</p>
                    </div>
                </a>
                <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                <div className="w-full flex flex-row items-center">
                    <div className="w-[90%] ">
                        <h5 className="mb-2 mt-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Opções de Reserva</h5>
                    </div>
                </div>
                {
                    loagind ?
                        <div className="w-full flex items-center justify-center flex-row my-4">
                            <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                        </div> :
                        modalities.length == 0 ?
                            <></> :
                            <div className="w-full lg:w-[50%] md:block lg:flex lg:flex-row lg:justify-between transition ">
                                <div className="w-full lg:w-[49%]">
                                    <p className="w-full flex-row flex items-cbenter ">
                                        <span className=" text-md font-bold tracking-tight text-gray-700 text-center">Ativos</span>
                                        <svg className="w-6 h-6 text-green-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </p>
                                    {
                                        _.filter(modalities, { SALOON: salao }).map(modality => (
                                            <div className="py-4 my-4 px-4 bg-gray-50 items-center rounded-lg shadow w-full flex justify-between">
                                                <span>{modality.NAME}</span>
                                                <div className="w-3/12 flex justify-between">
                                                    <button onClick={() => handleAddReserveOption(modality.ID)} className="hover:bg-gray-900 hover:text-white transition p-2 rounded-lg">Remover</button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="w-full lg:w-[49%]">
                                    <span className="mt-4 text-md font-bold tracking-tight text-gray-700 w-full">Disponiveis</span>
                                    {
                                        _.filter(modalities, function (e) { return e.SALOON != salao }).map(modality => (
                                            <div className="py-4 my-4 pl-4 bg-gray-900 items-center  text-white rounded-lg w-full flex justify-between">
                                                <span>{modality.NAME}</span>
                                                <div className="w-3/12 flex justify-between">
                                                    <button onClick={() => handleAddReserveOption(modality.ID)} className="hover:bg-white hover:text-gray-900 transition p-2 rounded-lg">
                                                        Adicionar</button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                }
            </div>
    );
}

export default ClientSaloon;