import { Card, CardBody, Collapse, Spinner, Typography } from "@material-tailwind/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/requests"
import Swal from "sweetalert2";
import _ from 'lodash';


function ClientSaloonTableOption() {
    const [tableOptionData, setTableOptionData] = useState<any>({});
    const [saloonOptionTableData, setSaloonOptionTableData] = useState<any>({});
    const [loagind, setLoading] = useState(false);
    const { estabelecimento, salao, opcao } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        getData();
        getTableOptionData();
    }, [])


    async function getData() {
        setLoading(true)
        await axios.get(`${BASE_URL}/opcao-mesa/${opcao}/tables`)
            .then(async res => {
                if (res.status != 200) {
                } else {
                    setTableOptionData(res.data.data)
                    console.log(res.data)
                    setLoading(false)
                }
            })
            .catch(err => {
            })
    }

    async function getTableOptionData() {
        setLoading(true)
        await axios.get(`${BASE_URL}/opcao-mesa/${opcao}`)
            .then(async res => {
                if (res.status != 200) {
                } else {
                    setSaloonOptionTableData(res.data.data[0])
                    console.log(res.data)
                    setLoading(false)
                }
            })
            .catch(err => {
            })
    }



    function handleDelete(id: number) {
        let table = _.find(tableOptionData, { ID: id });
        Swal.fire({
            icon: 'question',
            title: "Tem certeza?",
            text: `Esta prestes a excluir ${table.NAME}`,
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: `Sim, excluir`,
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isDenied) {
                await axios.delete(`${BASE_URL}/mesa/${table.ID}`)
                    .then(res => {
                        console.log(res)
                        Swal.fire({
                            text: `${table.NAME} excluida!`,
                            icon: "success",
                            footer: res.data.message
                        });
                        getData();
                    })
                    .catch(err => {
                        Swal.fire({
                            text: `${table.NAME} não pode ser excluida!`,
                            icon: "error",
                            footer: err.response.data.message
                        });
                    })
            }
        })
    }


    return (
        loagind ?
            <div className="w-full h-screen flex items-center justify-center">
                <Spinner className="h-24 w-24" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </div>
            :
            <div className="p-4 sm:ml-64">
                <div className="w-full flex flex-row items-center">
                    <div className="w-[90%] ">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{saloonOptionTableData.DESCRIPTION} </h5>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Capacidade: {saloonOptionTableData.MAX_CAPACITY} pessoas</p>
                    </div>
                    <div className="w-[5%] flex flex-row justify-center">
                        <button type="button" className="text-white  bg-blue-800  mr-1 hover:bg-blue-600  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 flex items-center justify-center dark:hover:bg-blue-100 dark:focus:ring-blue-800">
                            <svg className="w-6 h-6 text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                            </svg>
                        </button>
                        <button type="button" className="text-white bg-red-600 hover:bg-red-900  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 flex items-center justify-center dark:hover:bg-blue-100 dark:focus:ring-blue-800">
                            <svg className="w-6 h-6 text-gray-300 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fill-rule="evenodd" d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>
                <hr className="h-px mt-1 mb-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                <div className="w-full flex flex-row items-center">
                    <div className="w-[90%] ">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Mesas</h5>
                    </div>
                </div>
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 flex flex-row flex-wrap">
                    {
                        tableOptionData.length > 0 ?
                            tableOptionData.map((table: any) => (
                                <div key={table.ID}
                                    className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow py-4  text-center w-full md:flex-row lg:w-[23%] lg:flex-col md:w-full md:max-w-xl mb-2 lg:mb-2 lg:mr-2 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                                    <div className="flex flex-col justify-between p-4 leading-normal w-full md:w-full">
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{table.NAME}</h5>
                                    </div>
                                    <div className="w-[80%] flex flex-row justify-center">
                                        <button onClick={()=>navigate(`/estabelecimento/${estabelecimento}/salao/${salao}/opcao/${opcao}/mesa/editar-mesa/${table.ID}`)} type="button" className="text-white  bg-blue-800  mr-1 hover:bg-blue-600  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 flex items-center justify-center dark:hover:bg-blue-100 dark:focus:ring-blue-800">
                                            <svg className="w-6 h-6 text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                                            </svg>
                                        </button>
                                        <button type="button" onClick={() => handleDelete(table.ID)} className="text-white bg-red-600 hover:bg-red-900  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2 flex items-center justify-center dark:hover:bg-blue-100 dark:focus:ring-blue-800">
                                            <svg className="w-6 h-6 text-gray-300 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                <path fill-rule="evenodd" d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ))
                            :
                            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Não há mesas desse tipo</p>
                    }
                </div>
                <a href={`/estabelecimento/${estabelecimento}/salao/${salao}/opcao/${opcao}/nova-mesa`} className="flex flex-row items-center mt-3 bg-white border border-gray-200 rounded-lg shadow py-4  text-center w-full md:flex-row lg:w-3/12 lg:flex-col md:w-full md:max-w-xl mb-2 lg:mb-2 lg:mr-2 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                    <div className="flex flex-col items-center justify-center leading-normal w-full h-full md:w-full">
                        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <p className="my-3 font-normal text-gray-700 dark:text-gray-400">Adicionar {saloonOptionTableData.DESCRIPTION}</p>
                    </div>
                </a>
            </div>
    );
}

export default ClientSaloonTableOption;