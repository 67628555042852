import { useParams } from "react-router-dom";

function AdminSideBar() {
    const { estabelecimento } = useParams();


    return (
        <>
            <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-5000 rounded-lg sm:hidden hover:bg-gray-100 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>
            <aside id="default-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
                <div className="h-full px-3 py-4 overflow-y-auto bg-black dark:bg-gray-800">
                    <ul className="space-y-2 font-medium">
                        <li>
                            <a href={`/admin/dashboard`} className="flex flex-col text-white items-center rounded-lg mt-4 mb-10">
                                <span className="ms-3 font-bold">Reservei</span>
                                <img src="https://controleautomacao.com.br/sistema/img/logo.png" className="w-[65%] text-bold" alt="" />
                            </a>
                        </li>
                        <li>
                            <a href="/admin/estabelecimentos" className="flex items-center p-2 text-gray-100 rounded-lg dark:text-white hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-700 group">
                                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 4h12M6 4v16M6 4H5m13 0v16m0-16h1m-1 16H6m12 0h1M6 20H5M9 7h1v1H9V7Zm5 0h1v1h-1V7Zm-5 4h1v1H9v-1Zm5 0h1v1h-1v-1Zm-3 4h2a1 1 0 0 1 1 1v4h-4v-4a1 1 0 0 1 1-1Z" />
                                </svg>

                                <span className="flex-1 ms-3 whitespace-nowrap">Estabelecimentos</span>
                            </a>
                        </li>
                        <li>
                            <a href="/login" className="flex items-center p-2 text-gray-100 rounded-lg dark:text-white hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-700 group">
                                <svg className="w-6 rotate-90 h-6 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4" />
                                </svg>
                                <span className="flex-1 ms-3 whitespace-nowrap">Sair</span>
                                {/* <span className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span> */}
                            </a>
                        </li>
                    </ul>
                </div>
            </aside>

            {/* <div id="sidebar" className="lg:block hidden w-64 h-screen fixed rounded-none border-none bg-black">
                <div className="p-4 space-y-4">
                    <a href="/" aria-label="dashboard" className="relative px-4 py-3 flex items-center space-x-4 rounded-lg text-white bg-gradient-to-r from-sky-600 to-cyan-400">
                        <i className="fas fa-home text-white"></i>
                        <span className="-mr-1 font-medium">Inicio</span>
                    </a>
                    <a href="/login" className="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-5000 group">
                        <i className="fas fa-gift"></i>
                        <span>Login</span>
                    </a>
                    <a href="/restaurantes" className="px-4 py-3 flex items-center space-x-4 rounded-md text-gray-5000 group">
                        <i className="fas fa-store"></i>
                        <span>Restaurantes</span>
                    </a>
                </div>
            </div> */}
        </>

    )
}

export default AdminSideBar;