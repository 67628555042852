
function AdminDashboard() {

    return (
        <>
            <div className="p-4 sm:ml-64 flex items-center flex-col">
                <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Área Restrita</h1>
            </div>
        </>
    );
}

export default AdminDashboard;