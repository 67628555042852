import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/requests";
import Swal from "sweetalert2";
import { Spinner } from "@material-tailwind/react";

interface establishmentType {
    ID: number,
    NAME: string,
    EMAIL: string,
    PASSWORD: string,
    LINK: string,
    STATUS: string,
}

function EditSaloonTable() {

    const { estabelecimento, salao, opcao, mesa } = useParams();
    const [data, setData] = useState<any>({});
    const [title, setTitle] = useState<any>('');
    const [loagind, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, [])


    async function getData() {
        setLoading(true)
        await axios.get(`${BASE_URL}/mesa/${mesa}`)
            .then(res => {
                setData(res.data.data[0])
                setTitle(res.data.data[0].NAME)
                setLoading(false)                
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    async function handleSubmit(e) {
        e.preventDefault();
        await axios.patch(`${BASE_URL}/mesa/${mesa}`, data)
            .then(res => {
                Swal.fire({
                    text: `A mesa ${title} foi atualizada!`,
                    icon: "success",
                    didClose: () => {

                        navigate(`/estabelecimento/${estabelecimento}/salao/${salao}/opcao/${opcao}`)
                    }
                });
            })
            .catch(err => {
                Swal.fire({
                    text: `A mesa ${title} não pode ser atualizadas!`,
                    icon: "error",
                    footer: err.response.data.message
                });
            })
    }

    function handleChange(event: any) {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    return (

        loagind ?
            <div className="w-full flex items-center justify-center flex-row my-4">
                <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </div>
            :
            <div className="p-4 sm:ml-64">
                <h2 className="mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white w-full text-center">Editando mesa: {title}</h2>
                <section className="bg-gray-50 dark:bg-gray-900 flex flex-row items-center justify-center pt-8">
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Preencha com os dados da mesa
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="NAME" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Descrição</label>
                                    <input type="text" name="NAME" onChange={handleChange} id="NAME" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder={data.NAME} required={true} />
                                </div>
                                <button type="submit" className="w-full transition text-white bg-blue-900 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Salvar</button>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
    );
}

export default EditSaloonTable;