import { Dialog, Spinner } from "@material-tailwind/react";
import axios from "axios";
import { useEffect, useState } from "react";
import React from 'react'
import { BASE_URL, FRONT_URL } from "../../../utils/requests";
import { useParams } from "react-router-dom";
import _ from "lodash";
import Swal from "sweetalert2";
import { Tooltip } from "@mui/material";
import './style.css'

function TodayReserves() {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState<any>(false)
    const [dialogData, setDialogData] = React.useState<any>({})
    const [filterText, setFilteredText] = React.useState<any>('')
    const [saloonsData, setSaloonsData] = React.useState<any>([{}])
    const [reservesData, setReservesData] = React.useState<any>([{}])
    const [reservedTable, setReservedTable] = React.useState<any>();
    const [tableAvaliableData, setTableAvaliableData] = React.useState<any>({})
    const { estabelecimento } = useParams();
    const handleOpen = () => { setOpen(!open); getSaloonData() };

    useEffect(() => {
        getData();
    }, [])


    async function getData() {
        setLoading(true)
        await axios.get(`${BASE_URL}/reserva/local/hoje/${estabelecimento}`)
            .then(res => {
                if (res.status != 200) {
                } else {
                    console.log(res.data.data)
                    setReservesData(res.data.data)
                    setLoading(false)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }

    async function getSaloonData() {
        setLoading(true)
        await axios.get(`${BASE_URL}/mesa/${estabelecimento}/livre`)
            .then(res => {
                if (res.status != 200) {
                } else {
                    var x = _.uniq(Object.values(_.mapValues(res.data.data, 'SALOON')));
                    var index = 1;
                    var aux: Object[] = [];
                    var iterates = x.map(group => (
                        aux.push({ id: index++, nome: group })
                    ));
                    dialogData.SALOONS = aux
                    setSaloonsData(aux)
                    setTableAvaliableData(res.data.data)
                    console.log(res.data.data)
                }
                setLoading(false)
            })
            .catch(err => {
            })
    }

    const filteredItems = reservesData.filter((item) => {
        return JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
    });

    function handleChangeFilterText(e) {
        setFilteredText(e.target.value)
    }


    function getFomattedDate(date: string) {
        return new Date(date).toLocaleString('pt-BR', { timeZone: 'UTC' }).substring(0, 10)
    }

    function handleChangeReservedTable(e) {
        setReservedTable(_.find(tableAvaliableData, { "ID": parseInt(e.target.value) }))
    }

    async function confirmReserve() {
        await axios.post(`${BASE_URL}/reserva/confirmar/${dialogData.ID}`)
            .then(res => {
                if (res.status != 200) {
                } else {
                    getData();
                    Swal.fire({
                        icon: 'success',
                        title: 'Confirmado!',
                        text: `${res.data.message}`,
                        showConfirmButton: true,
                        timer: 1500,
                        timerProgressBar: true
                    })
                }
            })
            .catch(err => {
            })
    }

    function getWhatsConfirmationMessage(data: any) {
        let message = `Olá ${data.NAME}, precisamos que confirme sua reserva aqui: ${FRONT_URL}/reserve-confirm/${data.TOKEN}`
        return message;
    }

    async function handleUpdateReserve() {
        if (reservedTable) {
            await axios.post(`${BASE_URL}/mesa/reservar`, { RESERVEID: dialogData.ID, SALOONTABLEID: reservedTable.ID, DATE: dialogData.DATE.substring(0, 10) })
                .then(res => {
                    if (res.status != 200) {
                    } else {
                        setReservedTable(false)
                        getData();
                        Swal.fire({
                            icon: 'success',
                            title: 'Vinculado,',
                            text: `A mesa ${reservedTable.NAME} foi vinculada à reserva de ${dialogData.NAME}`,
                            showConfirmButton: true,
                            timer: 1500,
                            timerProgressBar: true
                        })
                    }
                })
                .catch(err => {
                })
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção,',
                text: `Selecione uma mesa primeiro`,
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true
            })
        }
    }


    async function sitDown() {
        if (dialogData.STATUS != 1 && dialogData.CONFIRMED) {
            await axios.post(`${BASE_URL}/mesa/sentar/${dialogData.ID}`)
                .then(res => {
                    if (res.status != 200) {
                    } else {
                        setReservedTable(false)
                        getData();
                        Swal.fire({
                            icon: 'success',
                            title: 'Vinculado,',
                            text: `A reserva de ${dialogData.NAME} se sentou à mesa ${dialogData.RESERVEDTABLE}`,
                            showConfirmButton: true,
                            timer: 1500,
                            timerProgressBar: true
                        })
                    }
                })
                .catch(err => {
                })
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção,',
                text: `Selecione uma reserva confirmada e com mesa primeiro`,
                showConfirmButton: true,
                timer: 200,
                timerProgressBar: true
            })
        }
    }

    async function getUp() {
        if (dialogData.STATUS = 1) {
            await axios.post(`${BASE_URL}/mesa/levantar/${dialogData.ID}`)
                .then(res => {
                    if (res.status != 200) {
                    } else {
                        setReservedTable(false)
                        getData();
                        Swal.fire({
                            icon: 'success',
                            title: 'Vinculado,',
                            text: `A mesa ${dialogData.RESERVEDTABLE} está livre!`,
                            showConfirmButton: true,
                            timer: 1500,
                            timerProgressBar: true
                        })
                    }
                })
                .catch(err => {
                })
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção,',
                text: `Os reservantes devem se sentar primeiro`,
                showConfirmButton: true,
                timer: 1500,
                timerProgressBar: true
            })
        }
    }

    function getReservedStatusName(statusID: number) {
        return statusID == 0 ? '' : ''
    }

    function handleCancelReservation() {
        Swal.fire({
            icon: 'question',
            title: "Tem certeza?",
            text: `Esta prestes a cancelar a reserva de ${dialogData.NAME}`,
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: `Sim, cancelar`,
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isDenied) {
                await axios.patch(`${BASE_URL}/reserva/cancelar/${dialogData.ID}`)
                    .then(res => {
                        console.log(res)
                        Swal.fire({
                            text: `Reserva de ${dialogData.NAME} cancelada`,
                            icon: "success",
                            footer: res.data.message
                        });
                        getData();
                    })
                    .catch(err => {
                        Swal.fire({
                            text: `Reserva de ${dialogData.NAME} não pode ser cancelada!`,
                            icon: "error",
                            footer: err.response.data.message
                        });
                    })
            }
        })

    }

    return (
        <div className="p-4 sm:ml-64">
            <section className="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5 h-[98vh] rounded-lg">
                <div className="mx-auto max-w-screen-xl px-4 lg:px-4">
                    <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                            <div className="w-full md:w-1/2">
                                <form className="flex items-center">
                                    <label htmlFor="simple-search" className="sr-only">Pesquisar</label>
                                    <div className="relative w-full">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <input type="text" id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                            value={filterText}
                                            onChange={handleChangeFilterText}
                                            placeholder="Pesquisar" required={true}></input>
                                    </div>
                                </form>
                            </div>
                            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                                <a href={`/estabelecimento/${estabelecimento}/reserva`} className="flex items-center cursor-pointer justify-center text-white bg-gray-900 hover:bg-gray-600 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                                    <svg className="h-3.5 w-3.5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" />
                                    </svg>
                                    Nova Reserva
                                </a>
                            </div>
                        </div>
                        <div className="overflow-x-auto">
                            {
                                reservesData.length > 0 ?
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-4 py-3">Nome</th>
                                                <th scope="col" className="px-4 py-3">Data</th>
                                                <th scope="col" className="px-4 py-3">Pessoas</th>
                                                <th scope="col" className="px-4 py-3">Salão</th>
                                                <th scope="col" className="px-4 py-3">Consumo</th>
                                                <th scope="col" className="px-4 py-3">Confirmação</th>
                                                <th scope="col" className="px-4 py-3">Mesa</th>
                                                <th scope="col" className="px-4 py-3">Observação?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredItems.map(reserve => (
                                                <tr key={reserve.ID} onClick={() => { setDialogData(reserve); handleOpen() }} className="border-b dark:border-gray-700 cursor-pointer hover:bg-gray-200">
                                                    <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{reserve.NAME}</th>
                                                    <td className="px-4 py-3">{getFomattedDate(reserve.DATE)}</td>
                                                    <td className="px-4 py-3 text-center">{reserve.PEOPLE_QTE}</td>
                                                    <td className="px-4 py-3">{reserve.SALOON}</td>
                                                    <td className="px-4 py-3">{reserve.MODALITY}</td>
                                                    <td className={`px-4 py-3 ${reserve.CONFIRMED == 2 ? 'text-red-600' : reserve.CONFIRMED == 1 ? 'text-green-300' : 'text-gray-500'}`}>{reserve.CONFIRMED == 2 ? 'Cancelada' : reserve.CONFIRMED == 1 ? 'Confirmado' : 'Pendente'}</td>
                                                    <td className="px-4 py-3 text-center">{reserve.RESERVEDTABLE}</td>
                                                    <td className="px-4 py-3 text-center flex flex-col items-center">
                                                        {
                                                            reserve.OBSERVATION ?
                                                                <Tooltip title={reserve.OBSERVATION} className="z-1000" placement="top-start">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="24" height="24" viewBox="0 0 24 24">
                                                                        <path className="text-gray-400" color="gray" d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                                                                    </svg>
                                                                </Tooltip>
                                                                : <></>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    :
                                    <p className="px-4 py-3 text-center font-bold text-gray-500 whitespace-nowrap dark:text-white">Sem reservas até o momento</p>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <Dialog open={open} className="!z-10" handler={handleOpen} onClick={() => { }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {
                    loading ?
                        <div className="w-full flex items-center justify-center my-4">
                            <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            Carregando dados da reserva...
                        </div>
                        :
                        <div className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div className="flex w-full items-center justify-center space-x-6 p-6">
                                <div className="flex-1 truncate">
                                    <div className="flex items-center divide-x justify-between ">
                                        <div className="w-1/2">
                                            {/* <span className="inline-flex flex-shrink-0 items-center rounded-md p-2 text-left shadow text-lg font-bold text-white bg-gray-900">{getFomattedDate(dialogData.DATE)}</span> */}
                                            <h3 className="truncate text-md font-bold text-gray-800">{dialogData.NAME}</h3>
                                            <p className="mt-1 truncate text-sm text-gray-500">{dialogData.SALOON} - {dialogData.PEOPLE_QTE} pessoas</p>
                                            <p className="mt-1 truncate text-sm text-gray-500">{dialogData.MODALITY}</p>
                                            {
                                                dialogData.OBSERVATION ?
                                                    dialogData.OBSERVATION.length > 25 ?
                                                        <p className="mt-1 truncate text-sm flex items-center text-bold text-gray-700">
                                                            <strong>Obs.: </strong>{dialogData.OBSERVATION.substring(0, 25)}...
                                                            <Tooltip title={dialogData.OBSERVATION} className="cursor-pointer" arrow placement="top">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="20" viewBox="0 0 24 24">
                                                                    <path className="text-gray-400" color="gray" d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
                                                                </svg>
                                                            </Tooltip>
                                                        </p>

                                                        :
                                                        <p className="mt-1 truncate text-sm text-bold text-gray-700">
                                                            <strong>Obs.: </strong>{dialogData.OBSERVATION}
                                                        </p>
                                                    : <></>
                                            }

                                            {dialogData.STATUS > 0 ?
                                                <span
                                                    className={`ml-2 inline-flex flex-shrink-0 items-center bg-green-50 text-blue-600 ring-green-600/20 rounded-full px-1.5 py-0.5 text-xs font-medium  ring-1 ring-inset `}>
                                                    {dialogData.RESERVEDTABLE}
                                                </span>
                                                : ""}
                                        </div>
                                        <div className="w-1/2 flex flex-col items-center">
                                            {
                                                dialogData.STATUS == 1 ?
                                                    <>
                                                        <button onClick={getUp} className="relative flex-col items-center flex justify-center w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                                            <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2M12 4v12m0-12 4 4m-4-4L8 8" />
                                                            </svg>

                                                            Levantar
                                                        </button>
                                                    </>

                                                    :
                                                    <>
                                                        <select defaultValue={dialogData.RESERVEDTABLEID} onChange={handleChangeReservedTable} id="large" className="block w-4/6 px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                            <option >Selecione um mesa</option>
                                                            {
                                                                tableAvaliableData ?
                                                                    saloonsData.map(saloon => (
                                                                        <optgroup key={saloon.name} label={saloon.nome}>
                                                                            {_.filter(tableAvaliableData, function (table) { return table.SALOON == saloon.nome && table.SALOONID == dialogData.SALOONID }).map(table => (
                                                                                <option key={table.ID} disabled={table.STATUS < 2} value={table.ID} selected={dialogData.RESERVEDTABLEID == table.ID}>{table.OPTIONNAME} - {table.NAME}</option>
                                                                            ))}
                                                                            <option disabled></option>
                                                                        </optgroup>
                                                                    )) :
                                                                    <></>
                                                            }
                                                        </select>
                                                        <div className="flex w-full">
                                                            {
                                                                dialogData.CONFIRMED == 1 ?
                                                                    <button onClick={sitDown} className="relative flex-col items-center flex justify-center w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                                                        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4" />
                                                                        </svg>
                                                                        Sentar
                                                                    </button> :
                                                                    <button onClick={confirmReserve} className="relative flex-col items-center flex justify-center w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                                                        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4" />
                                                                        </svg>
                                                                        Confirmar
                                                                    </button>

                                                            }
                                                            <button onClick={handleUpdateReserve} className="relative flex-col items-center flex justify-center w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                                                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961" />
                                                                </svg>
                                                                Atualizar
                                                            </button>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex">
                                <div className="flex w-1/2 flex-1 text-red-500 hover:bg-gray-50 hover:shadow rounded-md">
                                    <button onClick={handleCancelReservation} className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-red-900">
                                        <svg className="w-6 h-6 text-red-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                                        </svg>
                                        Cancelar
                                    </button>
                                </div>
                                <div className=" flex w-1/2 flex-1 hover:bg-gray-50 hover:shadow rounded-md">
                                    <a href={`https://wa.me/+55${dialogData.PHONE}?text=${getWhatsConfirmationMessage(dialogData)}`} target="_blank" className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-whatsapp" viewBox="0 0 16 16">
                                            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                                        </svg>
                                        Contato
                                    </a>
                                </div>
                            </div>
                        </div>
                }
            </Dialog >
        </div >

    )
} export default TodayReserves;