import { Stepper, Step, Button, step, Spinner } from "@material-tailwind/react";
import ReCAPTCHA from "react-google-recaptcha";
import { HomeIcon, CogIcon, UserIcon, CalendarIcon, ClockIcon, CheckIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/requests";
import { IMaskInput } from "react-imask";
import Swal from "sweetalert2";


interface establishmentType {
    ID: number,
    NAME: string,
    EMAIL: string,
    PASSWORD: string,
    LINK: string,
    STATUS: string,
}

function ReserveConfirmation() {

    const [data, setData] = useState<any>({});
    const [error, setError] = useState(false);
    const [state, setState] = useState({ isVerified: false });
    const [loading, setLoading] = useState<any>(false)
    const [isLastStep, setIsLastStep] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [isFirstStep, setIsFirstStep] = useState(false);
    const { reservetoken, id } = useParams();
    const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
    const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);


    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, [])


    async function getData() {
        setLoading(true);
        await axios.get(`${BASE_URL}/reserva/confirmar/token/${reservetoken}`)
            .then(res => {
                if (res.status != 200) {
                } else {
                    setData(res.data.data[0])
                    setLoading(false)
                }
            })
            .catch(err => {
                setLoading(false)
                setError(true)
                Swal.fire({
                    icon: 'error',
                    title: 'Ops,',
                    text: "Não encontramos esta reserva, favor entrar em contato com o estabelecimento para mais informações",
                    showConfirmButton: true,
                })
            })
    }

    async function handleSubmit(e) {
        e.preventDefault();
        await axios.post(`${BASE_URL}/reserva/confirmar/${data.ID}`)
            .then(res => {
                if (res.status == 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Confirmado',
                        text: "Sua reserva está confirmada, logo o estabelecimento reservará uma mesa para você.",
                        showConfirmButton: true,
                        didClose() {
                            getData();
                        },
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ops',
                        text: "Não conseguimos confirmar esta reserva, favor entrar em contato com o estabelecimento para confirmar manualmente",
                        showConfirmButton: true,
                        didClose() {
                            getData();
                        },
                    })
                }
            })
            .catch(err => {
            })
    }

    function getFomattedDate(date: string) {
        return new Date(date).toLocaleString('pt-BR', { timeZone: 'UTC' }).substring(0, 10)
    }

    function handleChaptchaVerify(value) {
        setState({
            isVerified: true
        })
    }

    return (
        <div className="w-full h-screen flex flex-col items-center">
            {loading ?
                <div className="w-full flex items-center justify-center my-4">
                    <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </div>
                :
                !data.CONFIRMED ?
                    <>
                        {
                            !error ?
                                <div className="p-4 w-full flex flex-col h-[90vh]">
                                    <div className="w-full h-[90%] ">
                                        <section className={`bg-gray-50 dark:bg-gray-900 flex  flex-row items-center justify-center h-[70vh]`}>
                                            <div className={` ${activeStep == 0 ? "flex" : "hidden"} bg-white rounded-lg shadow shadow-lg dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700`}>
                                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                                    <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 sm:text-2px md:text-2xl  dark:text-white">
                                                        Confirme os dados da sua reserva
                                                    </h1>
                                                    <div>
                                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Local</label>
                                                        <h5 className="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                            {data.LOCAL}
                                                        </h5>
                                                    </div>
                                                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                                                        <div>
                                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome</label>
                                                            <h5 className="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                                {data.NAME}
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contato</label>
                                                            <IMaskInput
                                                                className="bg-white text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white"
                                                                mask="(00) 00000-0000"
                                                                disabled={true}
                                                                value={data.PHONE}
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                                            <h5 className="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                                {data.EMAIL}
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="DATETIME" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data e Horário</label>
                                                            <h5 className="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                                {getFomattedDate(data.DATE)} às {data.TIME}
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <h5 className="text-lg font-bold leading-tight tracking-tight text-gray-900 md:text-lg dark:text-white">
                                                                {data.MODALITY}, para {data.PEOPLE_QTE} pessoas
                                                            </h5>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className={` ${activeStep == 1 ? "flex" : "hidden"} bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700`}>
                                                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                                        Resolva o captcha
                                                    </h1>
                                                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                                                        <div className="captcha">
                                                            <ReCAPTCHA
                                                                sitekey="6LeS-1khAAAAAOWzMs0OiUBnynUM9zCXQsJZumGz"
                                                                onChange={handleChaptchaVerify}
                                                            />
                                                        </div>
                                                        <Button type="submit" className="w-full" disabled={!state.isVerified} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder={undefined} >
                                                            Confirmar
                                                        </Button>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <Stepper
                                        className="mt-12"
                                        activeStep={activeStep}
                                        isLastStep={(value) => setIsLastStep(value)}
                                        isFirstStep={(value) => setIsFirstStep(value)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                                        <Step onClick={() => setActiveStep(2)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                            <UserIcon className="h-5 w-5" />
                                        </Step>
                                        <Step onClick={() => setActiveStep(3)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                            <CheckIcon className="h-5 w-5" />
                                        </Step>
                                    </Stepper>
                                    <div className="mt-4 flex justify-between">
                                        <Button onClick={handlePrev} disabled={isFirstStep} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                            Voltar
                                        </Button>
                                        <Button onClick={handleNext} disabled={isLastStep} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                            Próximo
                                        </Button>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                    </> :
                    <>
                        {
                            data.CONFIRMED == 2 ?
                                <div className="p-4 w-full  h-screen bg-gray-900">
                                    <div className="w-full h-[90%] flex flex-col items-center">
                                        <section className={`flex flex-col items-center justify-center h-[70vh] sm:w-full lg:w-[30%]`}>
                                            <div className="flex flex-col text-white items-center rounded-lg mt-4 mb-10">
                                                <span className="ms-3 font-bold">Reservei</span>
                                                <img src="https://controlesistemas-image-collection.s3.sa-east-1.amazonaws.com/logo_branca.png" className="w-[30%] text-bold" alt="" />
                                            </div>
                                            <div className={`bg-white rounded-lg shadow shadow-lg dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 h-fit w-fit p-8 flex-col flex items-center`}>
                                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 sm:text-lg md:text-lg text-center  dark:text-white">
                                                    Esta reserva foi cancelada!
                                                </h1>
                                                <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-center">
                                                    Contate {data.LOCAL} para mais detalhes...
                                                </p>

                                            </div>
                                        </section>
                                    </div>
                                </div>
                                :
                                <div className="p-4 w-full  h-screen bg-gray-900">
                                    <div className="w-full h-[90%] flex flex-col items-center">
                                        <section className={`flex flex-col items-center justify-center h-[70vh] sm:w-full lg:w-[30%]`}>
                                            <div className="flex flex-col text-white items-center rounded-lg mt-4 mb-10">
                                                <span className="ms-3 font-bold">Reservei</span>
                                                <img src="https://controlesistemas-image-collection.s3.sa-east-1.amazonaws.com/logo_branca.png" className="w-[30%] text-bold" alt="" />
                                            </div>
                                            <div className={`bg-white rounded-lg shadow shadow-lg dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 h-fit w-fit p-8 flex-col flex items-center`}>
                                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 sm:text-lg md:text-lg text-center  dark:text-white">
                                                    Sua reserva está confirmada
                                                </h1>
                                                <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-center">
                                                    {data.LOCAL} agradece e te espera, muito obrigado {data.NAME}!
                                                </p>

                                            </div>
                                        </section>
                                    </div>
                                </div>
                        }
                    </>
            }
        </div>
    );
}

export default ReserveConfirmation;