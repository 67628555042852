import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../utils/requests"
import { useStore } from "../../../Storage/Provider";

function Login() {

    const [data, setData] = useState({ USER: "", PASSWORD: "" });
    const { token, setToken, admin, setAdmin } = useStore()
    const navigate = useNavigate();

    useEffect(() => {

    }, [])

    async function handleSubmit(e) {
        e.preventDefault();
        await axios.post(`${BASE_URL}/admin/login`, data)
            .then(res => {
                console.log(res)
                if (res.status > 200) {
                    Swal.fire({
                        title: "Ops!",
                        text: res.data.message,
                        icon: "error"
                    });
                } else {
                    setToken(res.data.TOKEN)
                    setAdmin(true)
                    navigate('/admin/dashboard')
                }
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                title: "Ops!",
                text: err.response.data.message,
                icon: "error"
            });
            })
    }

    function handleChange(event: any) {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }


    return (
        <section className="bg-gray-900 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="flex flex-col text-white items-center rounded-lg mt-4 mb-10">
                    <span className="ms-3 font-bold">Área restrita - Reservei</span>
                    <img src="https://controleautomacao.com.br/sistema/img/logo.png" className="w-[30%] text-bold" alt="" />
                </div>
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="USER" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Usuário</label>
                                <input type="USER" name="USER" onChange={handleChange} id="email" placeholder="" required={true}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div>
                                <label htmlFor="PASSWORD" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Senha</label>
                                <input type="PASSWORD" name="PASSWORD" onChange={handleChange} id="PASSWORD" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    required={true} />
                            </div>
                            <button type="submit"
                                className={`w-full text-white hover:bg-primary-700 bg-gray-900  hover:bg-blue-900 transition font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>
                                Entrar
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;