import { Spinner, tab } from "@material-tailwind/react";
import axios from "axios";
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/requests";
import _ from 'lodash';
import Swal from "sweetalert2";

function ClientSaloons() {

    const [establishments, setEstablishments] = useState<any>([{}]);
    const [loagind, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getEstablishments();
    }, [])

    async function getEstablishments() {
        setLoading(true);
        await axios.get(`${BASE_URL}/local`)
            .then(res => {
                setEstablishments(res.data.data)
                setLoading(false)
            })
            .catch(err => {
            })
    }

    async function handleChangeActivation(establishment) {
        console.log(establishment)
        // e.preventDefault();
        await axios.patch(`${BASE_URL}/local/activation/${establishment.ID}/${establishment.STATUS == 1 ? 0 : 1}`)
            .then(res => {
                if (res.status == 200) {
                    console.log(res.data.data)
                    Swal.fire({
                        title: res.data.data.STATUS == 0 ? 'Desativado' : 'Ativado',
                        icon: "success",
                        didClose: () => {
                            getEstablishments();
                        }
                    });
                }
            })
            .catch(err => {
                Swal.fire({
                    title: "Ops!",
                    text: err.response.data.message,
                    icon: "error",
                });
            })
    }

    // function getTablesResume(saloon: number) {
    //     let saloonDefaultTables = _.filter(defaultTablesData, { SALOON: saloon });
    //     let saloonBusyTables = _.filter(busyTablesData, { SALOON: saloon });
    //     let total = _.sumBy(saloonDefaultTables, 'TOTAL');
    //     let busy = _.sumBy(saloonBusyTables, 'TOTAL');
    //     let fillPercent = (busy / total) * 100;

    //     return (
    //         <div className="mb-2 w-[80%] flex flex-col">
    //             <div className="flex w-full justify-between mb-1">
    //                 <span className="text-base font-medium text-gray-900 dark:text-white">Mesas totais</span>
    //                 <span className="text-sm font-medium text-gray-900 dark:text-white">
    //                     {total}
    //                 </span>
    //             </div>
    //             <div className="flex w-full justify-between mb-1">
    //                 <span className="text-base font-medium text-gray-900 dark:text-white">Mesas disponíveis</span>
    //                 <span className="text-sm font-medium text-gray-900 dark:text-white">{total - busy}</span>
    //             </div>
    //             <div className="flex w-full justify-between mb-1">
    //                 <span className="text-base font-medium text-gray-600 dark:text-white">Mesas ocupadas</span>
    //                 <span className="text-sm font-medium text-gray-600 dark:text-white">{busy}</span>
    //             </div>
    //             <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
    //                 <div className={`bg-gray-900 h-2.5 rounded-full`} style={{ width: `${fillPercent}%` }}></div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        loagind ?
            <div className="w-full flex items-center justify-center my-4">
                <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </div>
            :
            <div className="p-4 sm:ml-64">
                <h2 className="mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white w-full text-center">Estabelecimentos</h2>
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 flex flex-row flex-wrap">
                    {
                        establishments.length > 0 ?
                            establishments.map((establishment: any) => (
                                <a key={establishment.ID} href={`admin/estabelecimento/${establishment.ID}`} className="flex flex-col items-center justify-between bg-white border border-gray-200 rounded-lg shadow py-4  text-center w-full md:flex-row lg:w-3/12 lg:flex-col md:w-full md:max-w-xl mb-2 lg:mb-2 lg:mr-2 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                                    <div className="flex flex-col justify-between p-4 leading-normal w-full md:w-full">
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{establishment.NAME}</h5>
                                        <label onClick={() => { handleChangeActivation(establishment) }} className=" flex flex-col my-2 items-center cursor-pointer">
                                            <input type="checkbox" value="" className="sr-only peer" defaultChecked={establishment.STATUS == 1} />
                                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{establishment.STATUS == 1 ? "Ativo" : "Inativo"}</span>
                                        </label>
                                    </div>
                                </a>
                            ))
                            :
                            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Não há estabelecimentos cadastrados</p>
                    }
                </div>
                <a href="/admin/novo-estabelecimento" className="mt-4 flex flex-row items-center bg-white border border-gray-200 rounded-lg shadow py-4  text-center w-full md:flex-row lg:w-3/12 lg:flex-col md:w-full md:max-w-xl mb-2 lg:mb-2 lg:mr-2 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                    <div className="flex flex-col items-center justify-center leading-normal w-full h-full md:w-full">
                        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <p className="my-3 font-normal text-gray-700 dark:text-gray-400">Adicionar Estabelecimento</p>
                    </div>
                </a>
            </div>
    );
}

export default ClientSaloons;