import { Spinner, tab } from "@material-tailwind/react";
import axios from "axios";
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../utils/requests";
import _ from 'lodash';

interface establishmentType {
    ID: number,
    NAME: string,
    EMAIL: string,
    PASSWORD: string,
    LINK: string,
    STATUS: string,
}

interface establishmentSaloonsType {
    data: Array<{
        ID: number,
        NAME: string
    }>
}

function ClientSaloons() {
    const [establishmentData, setEstablishmentData] = useState<establishmentType>({
        ID: 0,
        NAME: "",
        EMAIL: "",
        PASSWORD: "",
        LINK: "",
        STATUS: "",
    });

    const [establishmentSaloons, setEstablishmentSaloons] = useState<any>({});
    const [defaultTablesData, setDefaultTablesData] = useState<any>({});
    const [busyTablesData, setBusyTablesData] = useState<any>({});
    const [loagind, setLoading] = useState(false);
    const navigate = useNavigate();
    const { estabelecimento } = useParams();

    useEffect(() => {
        getData();
        getSaloons();
    }, [])

    const totalTable = 15;
    const totalBusy = 5;

    async function getData() {
        await axios.get(`${BASE_URL}/local/${estabelecimento}/`)
            .then(async res => {
                if (res.status != 200) {
                } else {
                    setEstablishmentData(res.data.data[0])
                }
            })
            .catch(err => {
            })
    }

    async function getSaloons() {
        setLoading(true);
        await axios.get(`${BASE_URL}/local/${estabelecimento}/saloes-mesas`)
            .then(res => {
                setEstablishmentSaloons(res.data.data.SALOONS)
                setDefaultTablesData(res.data.data.TABLES.DEFAULT)
                setBusyTablesData(res.data.data.TABLES.BUSY)
                console.log(res)
                setLoading(false)
            })
            .catch(err => {
            })
    }

    function getTotalTable(id: number) {
        // console.log(_.sumBy(defaultTablesData,function(table){
        //     return table.SALOON = id ? table.TOTAL : 0;
        // }
        // ))
        return 0;
    }

    function getTablesResume(saloon: number) {
        let saloonDefaultTables = _.filter(defaultTablesData, { SALOON: saloon });
        let saloonBusyTables = _.filter(busyTablesData, { SALOON: saloon });
        let total = _.sumBy(saloonDefaultTables, 'TOTAL');
        let busy = _.sumBy(saloonBusyTables, 'TOTAL');
        let fillPercent = (busy / total) * 100;

        return (
            <div className="mb-2 w-[80%] flex flex-col">
                <div className="flex w-full justify-between mb-1">
                    <span className="text-base font-medium text-gray-900 dark:text-white">Mesas totais</span>
                    <span className="text-sm font-medium text-gray-900 dark:text-white">
                        {total}
                    </span>
                </div>
                <div className="flex w-full justify-between mb-1">
                    <span className="text-base font-medium text-gray-900 dark:text-white">Mesas disponíveis</span>
                    <span className="text-sm font-medium text-gray-900 dark:text-white">{total - busy}</span>
                </div>
                <div className="flex w-full justify-between mb-1">
                    <span className="text-base font-medium text-gray-600 dark:text-white">Mesas ocupadas</span>
                    <span className="text-sm font-medium text-gray-600 dark:text-white">{busy}</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div className={`bg-gray-900 h-2.5 rounded-full`} style={{ width: `${fillPercent}%` }}></div>
                </div>
            </div>
        )
    }

    return (
        loagind ?
            <div className="w-full flex items-center justify-center my-4">
                <Spinner className="h-12 w-12" color="blue" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </div>
            :
            <div className="p-4 sm:ml-64">
                <h2 className="mb-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white w-full text-center">Salões de {establishmentData.NAME}</h2>
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 flex flex-row flex-wrap">
                    {establishmentSaloons.length > 0 ?
                        establishmentSaloons.map((saloon: any) => (
                            <a key={saloon.ID} href={`/estabelecimento/${establishmentData.ID}/salao/${saloon.ID}`} className="flex flex-col items-center justify-between bg-white border border-gray-200 rounded-lg shadow py-4  text-center w-full md:flex-row lg:w-3/12 lg:flex-col md:w-full md:max-w-xl mb-2 lg:mb-2 lg:mr-2 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                                <div className="flex flex-col justify-between p-4 leading-normal w-full md:w-full">
                                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{saloon.NAME}</h5>
                                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{saloon.DESCRIPTION || "Ambiente top"}</p>
                                </div>
                                <div className="w-full flex flex-col items-center">
                                    {
                                        getTablesResume(saloon.ID)
                                    }
                                </div>
                            </a>
                        ))
                        :
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Não há salões cadastrados neste estabelecimento</p>
                    }
                </div>
                <a href={`/estabelecimento/${estabelecimento}/novo-salao`} className="mt-4 flex flex-row items-center bg-white border border-gray-200 rounded-lg shadow py-4  text-center w-full md:flex-row lg:w-3/12 lg:flex-col md:w-full md:max-w-xl mb-2 lg:mb-2 lg:mr-2 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                    <div className="flex flex-col items-center justify-center leading-normal w-full h-full md:w-full">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Novo</h5>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Adicionar salão</p>
                    </div>
                </a>
            </div>
    );
}

export default ClientSaloons;